import { h, Fragment, Component } from "preact";
import API from "../../classes/API";
import logo from "../../static/images/favicon-32x32.png";

class RequestPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      isSubmitted: false,
      messages: [],
      isError: false
    };
  }

  submitPasswordRequest(email) {
    const messages = [];
    let canProceed = false;
    setTimeout(() => {
      if (this.validateEmail(email)) {
        API.post(`/api/rest-auth/password/reset/`, "POST", {
          email: email
        }).then(response => {});
        canProceed = true;
      } else {
        messages.push("Please enter a valid email address");
        canProceed = false;
      }
      const isError = messages.length ? true : false;
      this.setState({
        loading: false,
        isError: isError,
        isSubmitted: canProceed,
        messages: messages
      });
    }, 1000);
  }

  redirectToHome() {
    setTimeout(() => {
      window.location.href = "/";
    }, 10000);
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  render() {
    const { visible, toggleModal } = this.props.state;
    const { loading, email, isSubmitted, messages, isError } = this.state;

    const renderErrorMessage = messages.length
      ? messages.map(message => {
          return <li className="error-item">{message}</li>;
        })
      : "";

    const renderRequest =
      isSubmitted === false ? (
        <div className="request-container">
          <h1> Forgot Your Password?</h1>
          <span>Enter your email address to reset your password.</span>
          <input
            class="u-full-width"
            type="email"
            placeholder="E-mail Address"
            id="email"
            name="email"
            value={this.state.email}
            onInput={e => {
              this.setState({ email: e.target.value });
            }}
          />

          <ul className={`error-list ${isError ? "form-activate" : ""}`}>
            {renderErrorMessage}
          </ul>

          <button
            className={`green ${loading ? "btn-activate" : ""}`}
            onClick={() => {
              this.setState({ loading: true }, () =>
                this.submitPasswordRequest(email)
              );
            }}
          >
            {loading && <i class="fa fa-circle-o-notch fa-spin"></i>}
            Reset Password
          </button>
        </div>
      ) : (
        <div className="request-container">
          {this.redirectToHome()}
          <h1>Check your Email.</h1>
          <span>
            We've sent an email to {`${email}`}. Please check your email and follow the instructions to change your password.
          </span>
          <span>
            If you don't see the email, check other places it might be, like
            your junk, spam, social or other folders.
          </span>
        </div>
      );

    return visible ? (
      <div>
        <div className="iuu-modal-shadow"> </div>
        <div className="iuu-modal iuu-modal-message">
          <i onClick={toggleModal} className="far fa-times-circle" />
          <div className="msg-modal">
            <img className="logo-modal" src={logo}></img>
            {renderRequest}
          </div>
        </div>
      </div>
    ) : (
      " "
    );
  }
}

export default RequestPasswordModal;
