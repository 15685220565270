import { h, Component } from "preact";
import Helper from "../../../../classes/Helper";

class BasicProfileInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onRenderProfileInfoItem(title, description) {
    return (
      <a className="item">
        <i className="fas fa-caret-right" />
        <div className="content">
          <div className="header">{title}:</div>
          <div className="description">{description}</div>
        </div>
      </a>
    );
  }

  onRenderProfileInfoItemLink(title, description, link) {
    return (
      <a className="item">
        <i className="fas fa-caret-right" />
        <div className="content">
          <div className="header">{title}:</div>
          <a href={link} target="_blank" className="description">
            {description}
          </a>
        </div>
      </a>
    );
  }

  onRenderProfileTitle(title) {
    //if the title includes a link, lets return an anchor tag

    if (title) {
      if (title.includes("http")) {
        const { url, linkText } = Helper.extractLinkAndTitle(title);

        return (
          <a href={url} target="_blank">
            {linkText}
          </a>
        );
      } else {
        return title;
      }
    }
  }

  onRenderProfileInfoItems() {
    const profileInfo = Object.entries(
      this.props.profile
    ).map(([key, value]) => ({ key, value }));

    return profileInfo.map((data, i) => {
      let { key, value } = data;

      if (value) {
        key === "name" ? (key = "Owner's Name") : null; //replace name with Owner's name

        if (key != "id") {
          //check if we're evaluating a link
          if (value.includes("http")) {
            //extract link data
            const { url, linkText } = Helper.extractLinkAndTitle(data.value);

            return this.onRenderProfileInfoItemLink(
              Helper.onBeautifyTitle(key),
              linkText,
              url
            );
          }

          return this.onRenderProfileInfoItem(
            Helper.onBeautifyTitle(data.key),
            data.value
          );
        }
      }
    });
  }
  render() {
    return (
      <div className="basic-info">
        <h1>{this.onRenderProfileTitle(this.props.profile.name)}</h1>
        <div className="ui divider" />
        <div className="ui list">{this.onRenderProfileInfoItems()}</div>
      </div>
    );
  }
}

export default BasicProfileInfo;
