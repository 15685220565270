import { h, Component } from "preact";
import Footer from "../Footer/footer";
import API from "../../classes/API";
import placeHolder from "../../static/images/placeholder1.svg"
import placeHolder1 from "../../static/images/placeholder2.svg"

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: []
    };
  }

  componentDidMount() {
    API.request(`/api/fishermen_stories`, "GET").then(response => {
      this.setState(
        {
          stories: response
        },
        () => {}
      );
    });
  }

  render() {
    const { stories } = this.state;
    const renderStories = stories.length ? (
      stories.map((stories, key) => {
        return (
          <div class="story-card" key={key}>
            <div class="card-img-container">
              <div class="card-img">
                {/* <div class="date">
                  <div class="day">27</div>
                  <div class="month">Mar</div>
                </div> */}
              </div>
            </div>
            <div class="card-body">
              <div class="category">Article</div>
              <p>
                <h2>{stories.title}</h2>
                <div class="summary-meta">
                  {/* <span class="author">
                    <i class="fa fa-user"></i>
                    Dyhia Belhabib
                  </span>
                  <span class="timestamp">
                    <i class="fa fa-clock-o"></i> 5 mins read
                  </span> */}
                </div>
                {stories.teaser}
              </p>

              <div class="card-link">
                <a href={`/fishermen-story/${stories.id}`}>Read Article</a>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="stories-empty-msg">
        <span>
          <i class="fa fa-clock-o"></i>
        </span>
        <span className="msg-text">No fishermen stories posted yet.</span>
      </div>
    );
    return (
      <div>
        <div className="page contact">
          <div className="page-image-wrapper" />
          <main className="story-container">
            <h1>{phrases["Fishermen Stories"]}</h1>
            <div className="stories-container" style={`--background: url(${placeHolder}); --background1: url(${placeHolder1})`}>{renderStories}</div>
          </main>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Stories;
