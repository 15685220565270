export default class Helper {
  static onBeautifyTitle(title) {
    return title
      .replace("_", " ")
      .split(" ")
      .map(word => {
        return word[0].toUpperCase() + word.substr(1, word.length);
      })
      .join(" ");
  }

  static extractLinkAndTitle(fullString) {
    const LINK_DETECTION_REGEX = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;

    const url = fullString.match(LINK_DETECTION_REGEX).join("");
    const linkText = fullString.replace(LINK_DETECTION_REGEX, "");

    return {
      url,
      linkText
    };
  }

  static generateOwnersString(owners) {
    return owners
      .map(owner => {
        return `${owner.name} (${owner.profile_type})`;
      })
      .join(", ");
  }

  static jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static parseKeys(keys) {
    return keys.map(key =>
      Helper.jsUcfirst(key)
        .split("_")
        .join(" ")
    );
  }
  static parseKey(key) {
    return Helper.jsUcfirst(key)
      .split("_")
      .join(" ");
  }
}
