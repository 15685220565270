export const layer_map = function(data, map, EEZS) {
  const categories = [
    "unauthorized",
    "species",
    "gear",
    "zone",
    "quota",
    "labour",
    "smuggling",
    "underreporting",
    "other"
  ];

  const colors = {
    unauthorized: "#EC619C",
    species: "#EC613F",
    gear: "#F5A04E",
    zone: "#FFE123",
    quota: "#B6C83C",
    labour: "#396AB1",
    smuggling: "#8AB2DF",
    underreporting: "#94376B",
    other: "#9B9B9B"
  };

  const render_map_incident = props => {
    const {
      vessel_id,
      vessel_name,
      year,
      category,
      eez_id,
      fishermen_story
    } = props;

    const story = fishermen_story ? JSON.parse(fishermen_story) : null;
    const categoryDescription = getCategoryDescription(category);

    return `<div class='map-incident'>
            <p>
              <a  href="/vessel/${vessel_id}/">${vessel_name} ${" "}<span class='map-incident-detail'>(${year})</span></a>
            </p>
            <p>
              <span class='map-incident-detail'>Category:</span> ${" "}${category} ${" "} <span class="map-incident-category ${category}" data-tooltip="${categoryDescription}">⦿</span>
              ${
                story
                  ? `<i class="fas fa-book-open story-icon" data-tooltip="${story.title}" onClick={window.location="/fishermen-story/${story.id}"}></i>`
                  : ""
              }
            </p>
        </div>`;
  };

  const getCategoryDescription = data => {
    const category = data.toLowerCase();

    switch (category) {
      case "unauthorized":
        return "Fishing without a valid license, permit, or illegal transshipment.";
      case "species":
        return "When an offence is related to bycatch, or discarding practices that are illegal, to a species size, prohibited species, etc.";
      case "gear":
        return "When the gear type is not conform to the standards, or illegal.";
      case "zone":
        return "Fishing in a prohibited zone, and/or in a closed area.";
      case "quota":
        return "Fishing beyond quota, mis-reporting quota species catch, cheating on quota by replacing one species with the other, etc.";
      case "labour":
        return "All forms of abuse (to crew, observers, etc.), slavery, human trafficking, murder, underpayment, over crewing, overworking conditions, etc.";
      case "smuggling":
        return "Smuggling of wildlife, illicit drugs, weapons, etc.";
      case "underreporting":
        return "Under-reporting of catches or effort (such as fishing capacity).";
      case "other":
        return "Other IUU related activities, or unspecified offences.";
    }
  };

  categories.forEach(function(item) {
    map.addSource(item, {
      type: "geojson",
      data: data[item],
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    map.addLayer({
      id: item + "_cluster",
      type: "circle",
      source: item,
      paint: {
        // 		// Use step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // 		// with three steps to implement three types of circles:
        // 		//   * Blue, 20px circles when point count is less than 100
        // 		//   * Yellow, 30px circles when point count is between 100 and 750
        // 		//   * Pink, 40px circles when point count is greater than or equal to 750
        "circle-color": colors[item],
        "circle-radius": ["step", ["get", "point_count"], 10, 5, 15, 30, 25],
        "circle-opacity": 0.6
      },
      layout: {
        visibility: "visible"
      }
    });

    map.addLayer({
      id: item + "_count",
      type: "symbol",
      source: item,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
        visibility: "visible"
      }
    });

    map.addLayer({
      id: item + "_point",
      type: "circle",
      source: item,
      filter: ["!has", "point_count"],
      paint: {
        "circle-color": colors[item],
        "circle-radius": 10,
        "circle-opacity": 0.8
      },
      layout: {
        visibility: "visible"
      }
    });

    map.on("click", item + "_point", function(e) {
      var props = e.features[0].properties;
      //{"eez": "Mauritanian Exclusive Economic Zone", "when": "", "flag": "Japan", "category": "unauthorized", "vessel_id": "3434"}
      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(render_map_incident(props))
        .addTo(map);
    });

    map.on("click", item + "_cluster", function(e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: [item + "_cluster"]
      });
      var clusterId = features[0].properties.cluster_id,
        point_count = features[0].properties.point_count,
        clusterSource = map.getSource(item);

      // Get all points under a cluster
      clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
        err,
        cluster_features
      ) {
        console.log(cluster_features);
        var html = cluster_features.reduce(function(acc_html, feature) {
          let props = feature.properties;
          return acc_html + render_map_incident(props);
        }, "");
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `<div class='popup-inner'>
              ${html}
            </div>`
          )
          .addTo(map);
      });
    });

    map.on("mouseover", item + "_cluster", function(e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: [item + "_cluster"]
      });
      var clusterId = features[0].properties.cluster_id,
        point_count = features[0].properties.point_count,
        clusterSource = map.getSource(item);

      // Get all points under a cluster
      clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
        err,
        cluster_features
      ) {
        console.log(cluster_features);

      });
    });

  });
};
/*
     <div class='close-btn'>
            <i class='far fa-times-circle'></i>
            </div>
*/

export const adjustPoint = function(category, points) {
  var new_points = [null, null];
  var theta = (Math.PI * 2) / 9;
  var r = 0.5;
  switch (category) {
    case "unauthorized":
      var angle = theta * 1;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "species":
      var angle = theta * 2;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "gear":
      var angle = theta * 3;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "zone":
      var angle = theta * 4;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "quota":
      var angle = theta * 5;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "labour":
      var angle = theta * 6;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "smuggling":
      var angle = theta * 7;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "underreporting":
      var angle = theta * 8;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
    case "other":
      var angle = theta * 9;
      new_points[0] = r * Math.cos(angle) + points[0];
      new_points[1] = r * Math.sin(angle) + points[1];
      return new_points;
  }
};
