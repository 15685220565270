import { h, Component } from "preact";
import Map from "./Map/map";

import MapMention from "./Map/MapMention";
import Footer from "./Footer/footer";
class MapPage extends Component {
  render() {
    return (
      <div>
        <Map />
        <MapMention />
        <Footer />
      </div>
    );
  }
}

export default MapPage;
