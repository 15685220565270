import { h, Component } from "preact";
import noUiSlider from "../../../vendor/nouislider.min";

class Filters extends Component {
  constructor() {
    super();
    this.state = {
      filtersOpen: false
    };
  }

  onFiltersToggle() {
    console.log("toggling filters");

    this.setState({
      filtersOpen: !this.state.filtersOpen
    });
  }

  componentDidMount() {
    if (window.innerWidth < 1100) {
      this.setState({
        filtersOpen: true
      });
    }
    let slider = document.getElementById("date_slider");
    noUiSlider.create(slider, {
      start: [1900, new Date().getFullYear()],
      connect: true,
      step: 1,
      range: {
        min: 2000,
        max: new Date().getFullYear()
      },
      pips: {
        mode: "range",
        density: 10,
        values: 6
      }
    });
    slider.noUiSlider.on("update", this.props.sliderYear);
  }

  render() {
    var fishing_category = [
      { label: phrases["Unauthorized"], id: 0, style: "border-color: #EC619C" },
      {
        label: phrases["Bycatch and Species related"],
        id: 1,
        style: "border-color: #EC613F"
      },
      { label: phrases["Gear Related"], id: 2, style: "border-color: #F5A04E" },
      {
        label: phrases["Zone/season related"],
        id: 3,
        style: "border-color: #FFE123"
      },
      {
        label: phrases["Quota related"],
        id: 4,
        style: "border-color: #B6C83C"
      },
      {
        label: phrases["Under-reporting related"],
        id: 7,
        style: "border-color: #94376B"
      }
    ];
    var others_category = [
      {
        label: phrases["Human rights and labour abuse"],
        id: 5,
        style: "border-color: #396AB1"
      },
      {
        label: phrases["Smuggling of drugs, arms, etc."],
        id: 6,
        style: "border-color: #8AB2DF"
      },
      {
        label: phrases["Other/unspecified"],
        id: 8,
        style: "border-color: #9B9B9B"
      }
    ];

    var create_checkboxes = list => {
      return list.map(item => (
        <div className="radio">
          <label>
            <input
              type="checkbox"
              name="crime"
              value={item.id}
              checked={this.props.categories[item.id].checked}
              onChange={e => this.props.checkItem(e)}
            />
            <div className="check" style={item.style} />
            {item.label}
          </label>
        </div>
      ));
    };

    const { filtersOpen } = this.state;

    return (
      <div
        className="ui vertical inverted sidebar menu right uncover visible"
        id="filters"
        style={
          filtersOpen ? "right: -255px !important;" : "right: 0 !important;"
        }
      >
        <div className="filter-wrapper">
          <div className="filter-toggle" onClick={() => this.onFiltersToggle()}>
            <i
              className={`fas fa-angle-double-${
                filtersOpen ? "left" : "right"
              }`}
            />
          </div>

          <div id="date_range">
            <h2>{phrases["Date Range"]}</h2>
            <div id="filter-input">
              <div className="ui form">
                <div className="inline fields date-wrapper">
                  <label>{phrases["From"]}</label>
                  <div className="field">
                    <input
                      type="number"
                      className="date-input"
                      value={this.props.minYear}
                      onChange={e => this.props.setYear(e, "minYear")}
                    />
                  </div>

                  <div className="date-from-to-divisor" />

                  <label>{phrases["To"]}</label>

                  <div className="field">
                    <input
                      type="number"
                      className="date-input"
                      value={this.props.maxYear}
                      onChange={e => this.props.setYear(e, "maxYear")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="date_slider" className="slider" />
          </div>

          <div id="crime_dates">
            <div className="radio">
              <label>
                <input
                  type="checkbox"
                  checked={this.props.showCrimesWithNoDate}
                  onChange={this.props.showCrimesWithNoDateFunction}
                />
                <div className="check" />
                {phrases["Show crimes without dates"]}
              </label>
            </div>
          </div>

          <div className="filter-black-divisor" />

          <div id="crime_categories">
            <h2>{phrases["Crime Categories"]}</h2>
            <div className="radio">
              <label>
                <input
                  type="checkbox"
                  checked={this.props.checkAll}
                  onChange={this.props.checkAllFunction}
                />
                <div className="check" />
                {phrases["Show all"]}
              </label>
            </div>

            <div className="filter-black-divisor internal-black-divisor" />

            <p>{phrases["Fishing and transshipment crimes"]}</p>
            {create_checkboxes(fishing_category)}

            <div className="filter-black-divisor internal-black-divisor" />

            <p>{phrases["Other transnational crimes"]}</p>
            {create_checkboxes(others_category)}
          </div>
        </div>

        <div className="filters-bottom-disclaimer">
          <div id="about-categories">
            <a href="/about#methods">{phrases["Disclaimer"]}</a>
          </div>
          <div className="short-disclaimer">
            {
              phrases[
                "The mention of any individual, company, organization, or other entity in this platform does not imply the violation of any laws, and should not be construed as such."
              ]
            }
          </div>

          <div id="copyright">
            <p>
              {
                phrases[
                  "Criminal Record of Fishing Vessels © 2018 Dyhia Belhabib"
                ]
              }
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Filters;
