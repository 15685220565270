import { h, Component } from 'preact'
import Footer from '../Footer/footer'
import API from '../../classes/API'
import Helper from '../../classes/Helper'

export default class VesselSearch extends Component {
  constructor() {
    super()
    this.state = {
      results: [] //list of vessels fetched from the server
    }
  }

  onVesselSearch() {
    // do request for vessel search and for profile as well
    API.request(
      `/api/vessels/search/?search_params=${this.props.keyword}`,
      'GET'
    ).then(response => {
      const vesselResults = response.vessel_results.map(result => {
        result.type = 'vessel'
        return result
      })

      API.request(
        `/api/profiles/search/?search_params=${this.props.keyword}`,
        'GET'
      ).then(response => {
        const companiesResults = response.profile_results.map(result => {
          result.type = 'company'
          return result
        })

        // then we concatenate both results and set to our state

        this.setState(
          {
            results: { vessels: vesselResults, companies: companiesResults }
          },
          () => console.log(this.state.results)
        )
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // This code will make sure that whenever we enter a new search parameter, a new search will be triggered.

    if (prevProps.keyword !== this.props.keyword) {
      this.onVesselSearch()
    }
  }

  componentDidMount() {
    this.onVesselSearch()
  }

  onRenderResults(type) {
    return this.state.results[type].map(result => {
      let resourceUrl

      switch (type) {
        case 'vessels':
          resourceUrl = `/vessel/${result.id}/`
          break
        case 'companies':
          resourceUrl = `/company/${result.id}/`
          break
      }

      return (
        <div className="item search-result ">
          <i className="ui avatar image fas fa-search-plus" />
          <div className="content truncate">
            <a href={resourceUrl} className="header">
              {Helper.jsUcfirst(result.type)}: {result.name}
            </a>
            {/* <div class="description">Last seen watching <a><b>Arrested Development</b></a> just now.</div> */}
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div className="page">
          <div className="page-image-wrapper" />
          <main className="ui text container">
            <h1>{phrases[""]}Search Results</h1>

            <div className="ui divider" />

            <p className="page-subtitle">
              {phrases["Based on the search criteria"]} "{this.props.keyword}", {phrases["we've found the following results:"]}
            </p>

            <div className="vessel-search-list">
              <div className="column-left">
                {this.state.results.vessels
                  ? this.onRenderResults('vessels')
                  : ''}
              </div>
              <div className="column-right">
                {this.state.results.vessels
                  ? this.onRenderResults('companies')
                  : ''}
              </div>

              {/* {this.state.results ? this.onRenderVesselResults() : null} */}
            </div>
          </main>
        </div>

        <Footer />
      </div>
    )
  }
}
