import { h, Component } from "preact";
import Footer from "../Footer/footer";

const Contact = props => {
  return (
    <div>
      <div className="page contact">
        <div className="page-image-wrapper" />
        <main className="ui text container">
          <h1>{phrases["Contact Us"]}</h1>

          <div className="ui divider" />

          <p className="page-subtitle">
          {phrases["Do you have a question for us? Please, fill the form below"]}
          </p>

          <form
            className="contact-form"
            action="https://formspree.io/dyhia@ecotrust.ca"
            method="POST"
          >
            <div className="row">
              <div className="six columns">
                <label for="exampleEmailInput">{phrases["Your email"]}</label>
                <input
                  className="u-full-width"
                  name="email"
                  type="email"
                  placeholder="test@mailbox.com"
                  id="exampleEmailInput"
                />
              </div>
              <div className="six columns">
                <label for="exampleRecipientInput">{phrases["Reason for contacting"]}</label>
                <select
                  className="u-full-width"
                  id="exampleRecipientInput"
                  name="type"
                >
                  <option value="Option 1">{phrases["Feedback"]}</option>
                  <option value="Option 2">{phrases["Questions"]}</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="twelve columns">
                <label for="exampleMessage">{phrases["Message"]}</label>
                <textarea
                  name="message"
                  className="u-full-width"
                  placeholder={phrases["Your message here..."]}
                  id="exampleMessage"
                />
              </div>
            </div>
            <div className="row">
              {/* <div className="twelve columns">
                <label class="example-send-yourself-copy">
                  <input type="checkbox" />
                  <span class="label-body">Send a copy to yourself</span>
                </label>
              </div> */}

              <div className="twelve columns">
                <input
                  className="button-primary iuu-button iuu-button-primary"
                  type="submit"
                  value={phrases["Submit"]}
                />
              </div>
            </div>
          </form>
        </main>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
