import { h, Component } from "preact";

class RequestInfoForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <p>
          {phrases[`Please indicate below all information about the fishing vessel and/or company you need to submit or request information for. If you are submitting information about an incident, please indicate whether this is a new entry or you would wish to provide more information on an existing case. Please be as concise as possible, and submit all necessary evidence of such incidents. Evidence can be an existing record of the incident (reported or confidential reports, media reports, etc. are accepted), official compliance report, or observation report. If no such evidence exists, you may be contacted (in confidence) to talk about the case. All information submissions and requests are important to us, we will treat the information as we receive it, within 24 to 48 hours.`]}
        </p>

        <form
          action="https://getform.io/f/df3c12a7-296f-43a7-ac42-e65b28452cc1"
          method="POST"
          enctype="multipart/form-data"
        >
          <div className="row">
            <div className="twelve columns">
              <label for="contactEmail">{phrases["Contact E-mail"]}</label>
              <input
                class="u-full-width"
                type="email"
                placeholder="youremail@domain.com"
                id="contactEmail"
                name="contactEmail"
              />
              <small>
                {phrases["*Contact information will only be available internally for follow-up if necessary."]}
              </small>
            </div>
          </div>

          <div className="row">
            <div className="four columns">
              <label for="contactType">{phrases["This is a:"]}</label>
              <select
                className="u-full-width"
                id="contactType"
                name="contactType"
              >
                <option value="vessel">{phrases["Vessel"]}</option>
                <option value="company">{phrases["Company"]}</option>
              </select>
            </div>

            <div className="four columns">
              <label for="reasonContact">{phrases["I want to..."]}</label>
              <select
                className="u-full-width"
                id="reasonContact"
                name="reasonContact"
              >
                <option value="submitInfo">{phrases["Submit Info"]}</option>
                <option value="requestInfo">{phrases["Request Info"]}</option>
              </select>
            </div>

            <div className="four columns">
              <label for="remainAnonymous">{phrases["Remain Anonymous?"]}</label>
              <select
                className="u-full-width"
                id="remainAnonymous"
                name="remainAnonymous"
              >
                <option value="yes" selected>
                  {phrases["Yes"]}
                </option>
                <option value="no">{phrases["No"]}</option>
              </select>
            </div>
          </div>

          <div className="vesselInfo">
            <div className="row">
              <div className="twelve columns">
                <h2>{phrases["Vessel Information"]}</h2>
              </div>
            </div>
            <div className="row">
              <div className="three columns">
                <label for="vesselName">{phrases["Vessel name"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="vesselName"
                  name="vesselName"
                />
              </div>

              <div className="three columns">
                <label for="IMO">IMO</label>
                <input class="u-full-width" type="text" id="IMO" name="IMO" />
              </div>

              <div className="three columns">
                <label for="MMSI">MMSI</label>
                <input class="u-full-width" type="text" id="MMSI" name="MMSI" />
              </div>

              <div className="three columns">
                <label for="GT">GT</label>
                <input class="u-full-width" type="text" id="GT" name="GT" />
              </div>
            </div>

            <div className="row">
              <div className="three columns">
                <label for="flag">{phrases["Flag"]}</label>
                <input class="u-full-width" type="text" id="flag" name="flag" />
                <small>{phrases["At the time of the incident"]}</small>
              </div>

              <div className="three columns">
                <label for="lengthWidth">{phrases["Length/Width"]}</label>
                <input
                  class="u-full-width"
                  type="number"
                  id="lengthWidth"
                  name="lengthWidth"
                />
              </div>

              <div className="three columns">
                <label for="callSign">{phrases["Call sign"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="callSign"
                  name="callSign"
                />
              </div>

              <div className="three columns">
                <label for="smallScaleLargeScale">
                  {phrases["Small scale or large scale?"]}
                </label>
                <select
                  className="u-full-width"
                  id="smallScaleLargeScale"
                  name="smallScaleLargeScale"
                >
                  <option value="small" selected>
                    {phrases["Small"]}
                  </option>
                  <option value="large">{phrases["Large (Industrial)"]}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="companyInfo">
            <div className="row">
              <div className="twelve columns">
                <h2>{phrases["Company Information"]}</h2>
              </div>
            </div>
            <div className="row">
              <div className="three columns">
                <label for="companyName">{phrases["Company name (owner)"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="companyName"
                  name="companyName"
                />
              </div>

              <div className="three columns">
                <label for="ownerAddress">{phrases["Address of owner"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="ownerAddress"
                  name="ownerAddress"
                />
              </div>

              <div className="three columns">
                <label for="managerName">{phrases["Manager name"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="managerName"
                  name="managerName"
                />
              </div>

              <div className="three columns">
                <label for="managerAddress">{phrases["Address of Manager"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="managerAddress"
                  name="managerAddress"
                />
              </div>
            </div>
          </div>

          <div className="companyInfo">
            <div className="row">
              <div className="twelve columns">
                <h2>{phrases["Incident Information"]}</h2>
              </div>
            </div>
            <div className="row">
              <div className="three columns">
                <label for="incidentInfraction">{phrases["Infraction"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="incidentInfraction"
                  name="incidentInfraction"
                />
                <small>{phrases["Please provide a detailed account"]}</small>
              </div>

              <div className="three columns">
                <label for="incidentYear">{phrases["Year of the incident"]}</label>
                <input
                  class="u-full-width"
                  type="number"
                  id="incidentYear"
                  name="incidentYear"
                />
              </div>

              <div className="three columns">
                <label for="incidentEEZ">{phrases["EEZ of the incident"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="incidentEEZ"
                  name="incidentEEZ"
                />
                <small>{phrases["Where did the infraction occur?"]}</small>
              </div>

              <div className="three columns">
                <label for="incidentSanctioned">{phrases["Offender sanctioned?"]}</label>
                <input
                  class="u-full-width"
                  type="text"
                  id="incidentSanctioned"
                  name="incidentSanctioned"
                />
              </div>
            </div>
            <div className="row">
              <div className="six columns">
                <label for="incidentWhoCaughtInfraction">
                  {phrases["Who caught the infraction?"]}
                </label>
                <select
                  className="u-full-width"
                  id="incidentWhoCaughtInfraction"
                  name="incidentWhoCaughtInfraction"
                >
                  <option value="small">{phrases["Small-scale"]}</option>
                  <option value="large">{phrases["Large (industrial)"]}</option>
                </select>
              </div>

              <div className="six columns">
                <label for="supportDocuments">{phrases["Support documents"]}</label>
                <input class="u-full-width" input type="file" name="file" />
                <input class="u-full-width" input type="file" name="file" />
                <input class="u-full-width" input type="file" name="file" />
                <input class="u-full-width" input type="file" name="file" />
                <input class="u-full-width" input type="file" name="file" />
              </div>
            </div>
            <div className="row">
              <div className="twelve columns">
                <label for="otherComments">{phrases["Message"]}</label>
                <textarea
                  class="u-full-width"
                  placeholder={phrases["Please type any other comments here"]}
                  name="otherComments"
                  id="otherComments"
                />
              </div>
            </div>
            <div className="row">
              <div className="twelve columns">
                <input class="button-primary" type="submit" value={phrases["Send"]} />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RequestInfoForm;
