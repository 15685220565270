import { h, Component } from "preact";
import Filters from "./Filters/filters";
import eez_centers from "../../data/EEZ_centers";
import API from "../../classes/API";
import { layer_map, adjustPoint } from "./utils";

mapboxgl.accessToken =
  "pk.eyJ1IjoiY291bnRhYmxlLXdlYiIsImEiOiJjamQyZG90dzAxcmxmMndtdzBuY3Ywa2ViIn0.MU-sGTVDS9aGzgdJJ3EwHA";
//"pk.eyJ1IjoiZWxpYW5hIiwiYSI6ImNpZ3UyYXM0bjBiMzN1Nm0xOTE2ODZpbmQifQ.Tgp2pX4xlCZRtED9A_wsCw";

var map;
let EEZS = null;
let CATEGORIES = {};

// filters for classifying incidents into five categories based on magnitude
var mag1 = ["<", ["get", "mag"], 2];
var mag2 = ["all", [">=", ["get", "mag"], 2], ["<", ["get", "mag"], 3]];
var mag3 = ["all", [">=", ["get", "mag"], 3], ["<", ["get", "mag"], 4]];
var mag4 = ["all", [">=", ["get", "mag"], 4], ["<", ["get", "mag"], 5]];
var mag5 = [">=", ["get", "mag"], 5];

class Map extends Component {
  constructor() {
    super();
    this.state = {
      map_data: {},
      minYear: 2000,
      maxYear: new Date().getFullYear(),
      checkAll: true,
      showCrimesWithNoDate: true,
      checked_categories: [
        {
          name: "unauthorized",
          checked: true
        },
        {
          name: "species",
          checked: true
        },
        {
          name: "gear",
          checked: true
        },
        {
          name: "zone",
          checked: true
        },
        {
          name: "quota",
          checked: true
        },
        {
          name: "labour",
          checked: true
        },
        {
          name: "smuggling",
          checked: true
        },
        {
          name: "underreporting",
          checked: true
        },
        {
          name: "other",
          checked: true
        }
      ]
    };
  }

  componentDidMount() {
    Promise.all([
      API.request(`/api/eezs/map/`),
      API.request(`/api/incidents/`),
      API.request(`/api/categories/`)
    ]).then(([eezs, response, categories]) => {
      EEZS = eezs;
      categories.forEach(cat => {
        CATEGORIES[cat.id] = cat.name;
      });

      this.data_to_geojson(response);

      map = new mapboxgl.Map({
        container: "mapbox", // container id
        style: "mapbox://styles/countable-web/cjynx7qkz3tem1crapriudh30",
        center: [16.758389, 41.254111], // starting position
        zoom: 2, // starting zoom
        attributionControl: false
      });

      // Add zoom and rotation controls to the map.
      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "bottom-left");

      var { map_data } = this.state;

      map.on("load", () => {
        this._loaded = true;
        layer_map(map_data, map, EEZS);

        return; // disable donut vis for now.

        // add a clustered GeoJSON source for a sample set of incidents
        map.addSource("incidents", {
          type: "geojson",
          data: "/vessel/incident_geojson",
          //                "data": "https://docs.mapbox.com/mapbox-gl-js/assets/incidents.geojson",
          cluster: true,
          clusterRadius: 80,
          clusterProperties: {
            // keep separate counts for each magnitude category in a cluster
            mag1: ["+", ["case", mag1, 1, 0]],
            mag2: ["+", ["case", mag2, 1, 0]],
            mag3: ["+", ["case", mag3, 1, 0]],
            mag4: ["+", ["case", mag4, 1, 0]],
            mag5: ["+", ["case", mag5, 1, 0]]
          }
        });
        // circle and symbol layers for rendering individual incidents (unclustered points)
        map.addLayer({
          id: "incident_circle",
          type: "circle",
          source: "incidents",
          filter: ["!=", "cluster", true],
          paint: {
            "circle-color": [
              "case",
              mag1,
              colors[0],
              mag2,
              colors[1],
              mag3,
              colors[2],
              mag4,
              colors[3],
              colors[4]
            ],
            "circle-opacity": 0.6,
            "circle-radius": 12
          }
        });
        map.addLayer({
          id: "incident_label",
          type: "symbol",
          source: "incidents",
          filter: ["!=", "cluster", true],
          layout: {
            "text-field": [
              "number-format",
              ["get", "mag"],
              {
                "min-fraction-digits": 1,
                "max-fraction-digits": 1
              }
            ],
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-size": 10
          },
          paint: {
            "text-color": ["case", ["<", ["get", "mag"], 3], "black", "white"]
          }
        });

        // objects for caching and keeping track of HTML marker objects (for performance)
        let markers = {};
        let markersOnScreen = {};

        let updateMarkers = () => {
          var newMarkers = {};
          var features = map.querySourceFeatures("incidents");

          // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
          // and add it to the map if it's not there already
          for (var i = 0; i < features.length; i++) {
            var coords = features[i].geometry.coordinates;
            var props = features[i].properties;
            if (!props.cluster) continue;
            var id = props.cluster_id;
            // create a simple popup.
            var popup = new mapboxgl.Popup({
              offset: 25
            }).setText(
              "Construction on the Washington Monument began in 1848."
            );

            var marker = markers[id];
            marker.setPopup(popup); // sets a popup on this marker

            if (!marker) {
              var el = createDonutChart(props);
              marker = markers[id] = new mapboxgl.Marker({
                element: el
              }).setLngLat(coords);
            }
            newMarkers[id] = marker;

            if (!markersOnScreen[id]) marker.addTo(map);
          }
          // for every marker we've added previously, remove those that are no longer visible
          for (id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove();
          }
          markersOnScreen = newMarkers;
        };

        // after the GeoJSON data is loaded, update markers on the screen and do so on every map move/moveend
        map.on("data", function(e) {
          if (e.sourceId !== "incidents" || !e.isSourceLoaded) return;

          map.on("move", updateMarkers);
          map.on("moveend", updateMarkers);
          updateMarkers();
        });
      });
      //     });
      // });
    });
  }

  componentDidUpdate() {
    if (this._loaded) {
      this.filter_by_category();
      this.filter_by_year();
    }
  }

  filter_by_year() {
    var { map_data, minYear, maxYear } = this.state;
    let showingEmptyDates = this.state.showCrimesWithNoDate;

    let new_map_data = Object.assign({}, map_data);

    for (var key in new_map_data) {
      if (new_map_data.hasOwnProperty(key)) {
        var points = new_map_data[key]["orig_features"];
        var year_filtered = points.filter(function(point) {
          //if the value is not an date and we are showing crimes without dates
          if (isNaN(point.properties.year) && showingEmptyDates) return true;
          else
            return (
              point.properties.year <= maxYear &&
              point.properties.year >= minYear
            );
        });

        new_map_data[key]["features"] = year_filtered;

        map.getSource(key).setData(new_map_data[key]);
      }
    }
  }

  filter_by_category() {
    for (var category of this.state.checked_categories) {
      if (category.checked) {
        map.setLayoutProperty(
          category.name + "_cluster",
          "visibility",
          "visible"
        );
        map.setLayoutProperty(
          category.name + "_point",
          "visibility",
          "visible"
        );
        map.setLayoutProperty(
          category.name + "_count",
          "visibility",
          "visible"
        );
      } else {
        map.setLayoutProperty(category.name + "_cluster", "visibility", "none");
        map.setLayoutProperty(category.name + "_point", "visibility", "none");
        map.setLayoutProperty(category.name + "_count", "visibility", "none");
      }
    }
  }

  data_to_geojson(data) {
    var new_data = {
      unauthorized: { features: [], orig_features: [] },
      species: { features: [], orig_features: [] },
      gear: { features: [], orig_features: [] },
      zone: { features: [], orig_features: [] },
      quota: { features: [], orig_features: [] },
      labour: { features: [], orig_features: [] },
      smuggling: { features: [], orig_features: [] },
      underreporting: { features: [], orig_features: [] },
      other: { features: [], orig_features: [] }
    };

    data.forEach(element => {
      element.c.forEach(cid => {
        let cat = CATEGORIES[cid];
        let year = parseInt(element.year);
        if (Number.isInteger(year)) {
          new_data[cat]["features"].push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: adjustPoint(cat, EEZS[element.eez_id])
            },
            properties: {
              year: year,
              eez_id: element.eez_id,
              category: cat,
              vessel_id: element.vessel_id,
              vessel_name: element.n,
              fishermen_story: element.fishermen_story
            }
          });
        } else {
          console.log((element.year = " is not a number"));
        }
      });
    });
    for (let cat in new_data) {
      new_data[cat]["orig_features"] = new_data[cat]["features"];
    }
    this.setState({ map_data: new_data });
  }

  setYear(e, minOrMax) {
    this.setState({ [minOrMax]: e.target.value });
  }

  sliderYear(year) {
    this.setState({
      minYear: Math.floor(year[0]),
      maxYear: Math.floor(year[1])
    });
  }

  showCrimesWithNoDateFunction() {
    this.setState({
      showCrimesWithNoDate: !this.state.showCrimesWithNoDate
    });
    this.filter_by_year();
  }

  checkItem(e) {
    let id = e.target.value;
    var new_categories = [...this.state.checked_categories];

    new_categories[id].checked = !new_categories[id].checked;

    if (this.state.checkAll) this.state.checkAll = !this.state.checkAll;

    this.setState({
      checked_categories: new_categories,
      checkAll: this.state.checkAll
    });
  }

  checkAllFunction() {
    var new_categories = [...this.state.checked_categories];
    for (var item of new_categories) {
      this.state.checkAll ? (item.checked = false) : (item.checked = true);
    }
    this.setState({
      checkAll: !this.state.checkAll,
      checked_categories: new_categories
    });
  }

  render() {
    return (
      <div id="map_container">
        <div id="mapbox" />
        <Filters
          categories={this.state.checked_categories}
          checkAll={this.state.checkAll}
          checkItem={this.checkItem.bind(this)}
          checkAllFunction={this.checkAllFunction.bind(this)}
          showCrimesWithNoDate={this.state.showCrimesWithNoDate}
          showCrimesWithNoDateFunction={this.showCrimesWithNoDateFunction.bind(
            this
          )}
          minYear={this.state.minYear}
          maxYear={this.state.maxYear}
          setYear={this.setYear.bind(this)}
          sliderYear={this.sliderYear.bind(this)}
        />
      </div>
    );
  }
}

var colors = ["#fed976", "#feb24c", "#fd8d3c", "#fc4e2a", "#e31a1c"];

// code for creating an SVG donut chart from feature properties
const createDonutChart = props => {
  var offsets = [];
  var counts = [props.mag1, props.mag2, props.mag3, props.mag4, props.mag5];
  var total = 0;
  for (var i = 0; i < counts.length; i++) {
    offsets.push(total);
    total += counts[i];
  }
  var fontSize = total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
  var r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
  var r0 = Math.round(r * 0.6);
  var w = r * 2;

  var html =
    '<svg width="' +
    w +
    '" height="' +
    w +
    '" viewbox="0 0 ' +
    w +
    " " +
    w +
    '" text-anchor="middle" style="font: ' +
    fontSize +
    'px sans-serif">';

  for (i = 0; i < counts.length; i++) {
    html += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      colors[i]
    );
  }
  html +=
    '<circle cx="' +
    r +
    '" cy="' +
    r +
    '" r="' +
    r0 +
    '" fill="white" /><text dominant-baseline="central" transform="translate(' +
    r +
    ", " +
    r +
    ')">' +
    total.toLocaleString() +
    "</text></svg>";

  var el = document.createElement("div");
  el.innerHTML = html;
  return el.firstChild;
};

const donutSegment = (start, end, r, r0, color) => {
  if (end - start === 1) end -= 0.00001;
  var a0 = 2 * Math.PI * (start - 0.25);
  var a1 = 2 * Math.PI * (end - 0.25);
  var x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  var x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  var largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    "L",
    r + r * x0,
    r + r * y0,
    "A",
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    "L",
    r + r0 * x1,
    r + r0 * y1,
    "A",
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />'
  ].join(" ");
};

export default Map;
