import { h, Component } from "preact";

export default class Modal extends Component {
  render() {
    return this.props.visible ? (
      <div>
        <div className="iuu-modal-shadow"> </div>

        <div className={`iuu-modal iuu-modal-${this.props.modalSize}`}>
          <i
            onClick={this.props.onCloseModal}
            className="far fa-times-circle"
          />
          {this.props.children}
        </div>
      </div>
    ) : null;
  }
}
