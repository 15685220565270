import { h, Component } from "preact";
import VesselTable from "../VesselTable";

class FlagHistory extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const table = {
      title: "Flag History",
      columns: ["From", "To", "Flag"],
      rows: [
        ["2016-9-9", "-", "Unknow"],
        ["2008-11-24", "-", "Malta"],
        ["2019-9-9", "2016-99", "Libya"]
      ]
    };

    return (
      <div className="basic-info">
        <VesselTable table={table} />
      </div>
    );
  }
}

export default FlagHistory;
