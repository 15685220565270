import { h, Component } from "preact";

class VesselTable extends Component {
  onRenderColumns(columns) {
    return columns.map((column, i) => (
      <th className={i % 2 === 0 ? "bg-color" : null}>{column}</th>
    ));
  }
  onRenderRows(rows) {
    return rows.map(row => {
      return (
        <tr>
          {row.map(r => (
            <td>{r}</td>
          ))}
        </tr>
      );
    });
  }

  render() {
    const { title, columns, rows, footerLabel } = this.props.table;

    return (
      <div className="flag-table">
        <div className="table-heading">
          <h3>{title}</h3>
        </div>
        <div className="flag-table-details">
          <table>
            <tr>{this.onRenderColumns(columns)}</tr>

            {this.onRenderRows(rows)}
          </table>
          <div
            className="footer-label"
            dangerouslySetInnerHTML={{ __html: footerLabel }}
          />
        </div>
      </div>
    );
  }
}

export default VesselTable;
