import { h, Component } from "preact";
import countableLogo from "../../static/images/countable-logo.svg";

const MapMention = props => {
  return (
    <div className="map-mention">
      <span>
        Data extracted from the Criminal Record of Fishing vessels © Built with
        love by{" "}
        <a
          href="https://countable.ca"
          target="_blank"
          className="map-countable-bottom-link"
        >
          Countable.ca{"  "}
          <img
            src={countableLogo}
            alt="countable-logo"
            className="map-countable-logo"
          />
        </a>
      </span>
    </div>
  );
};
export default MapMention;
