import { h, Component } from "preact";
import logo from "../../static/images/iuu-logo.svg";
import { RegistrationSuccessModal, WelcomeOfficialModal } from "../UI/Modals";
import LoginModal from "../UI/SignInModal";
import SignUpModel from "../UI/SignUpModal";
import ResetPasswordModal from "../UI/ResetPasswordModal";
import Cookies from "js-cookie";
import detectBrowserLanguage from "detect-browser-language";
import { route } from "preact-router";
import API from "../../classes/API";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      searchKeyword: "",
      languages: [
        { language: "English", value: "en" },
        { language: "French", value: "fr" },
        { language: "Portugese", value: "pt" },
        { language: "Spanish", value: "es" },
        { language: "Arabic", value: "ar" }
      ],
      currentLang: { language: "English", value: "en" },
      showToggle: false,
      showNotif: false,
      showUser: false,
      showModal: false,
      modalType: "login",
      isLoggedIn: false,
      userDetail: {}
    };
  }

  componentDidMount() {
    const { languages } = this.state;

    const browserLanguage = languages.find(language =>
      detectBrowserLanguage().includes(language.value)
    )
      ? detectBrowserLanguage()
      : "en";

    const savedLang = localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : this.loadPreferLanguage(browserLanguage);

    let currentLang = languages.find(function(language) {
      return savedLang.includes(language.value);
    });

    let isLoggedIn = localStorage.getItem("isLoggedIn") ? true : false;
    this.setState({
      currentLang,
      isLoggedIn
    });

    document.addEventListener("click", e => {
      if (this.state.showUser || this.state.showToggle) {
        this.setState({ showUser: false, showToggle: false });
      }
    });

    this.checkIfForLogin();
    isLoggedIn ? this.getUserDetails() : "";
  }

  loadPreferLanguage(lang) {
    const { getLang } = this.props;
    getLang(lang);
    return lang;
  }

  checkIfForLogin() {
    setTimeout(() => {
      const isValid =
        window.location.pathname.split("/").pop() == "account-verified" ||
        window.location.pathname.split("/").pop() == "password-changed";
      sessionStorage.getItem("forLogin") || isValid
        ? this.showModal("login")
        : "";
      sessionStorage.removeItem("forLogin");
    }, 500);
  }

  getUserDetails() {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    API.request(`/api/user/details/${userDetail.email}/`, "GET").then(
      response => {
        this.setState({ userDetail: response });
      }
    );
  }

  onNavBarToggle() {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  onVesselSearch() {
    console.log("searching for vessel...");
    route(`/search/${this.state.searchKeyword}/`, true);
  }

  getLanguages() {
    let { languages, currentLang } = this.state;
    const returnValue = languages.length ? (
      languages.map((language, key) => {
        return (
          <li
            key={key}
            onClick={() => this.languageAPICall(language)}
            class={currentLang.value === language.value ? "selected" : ""}
          >
            <div className={`flag-icon`} />
            <span
              className={currentLang.value === language.value ? "active" : ""}
            >
              {" "}
              {language.language}
            </span>
          </li>
        );
      })
    ) : (
      <div>No langauges</div>
    );
    return returnValue;
  }

  toggleLanguage() {
    this.setState(state => ({
      showToggle: !state.showToggle,
      showUser: false
    }));
  }

  toggleNotification() {
    this.setState(state => ({ showNotif: !state.showNotif }));
  }

  languageAPICall(language) {
    const { getLang } = this.props;
    getLang(language.value);
    this.setState({
      showToggle: false,
      currentLang: language
    });
  }

  toggleMobMenu() {
    const mobMenu = document.getElementById("mob-menu");
    mobMenu.classList.toggle("mob-menu-open");
    this.noScroll();
  }

  closeMobMenu() {
    const mobMenu = document.getElementById("mob-menu");
    mobMenu.classList.remove("mob-menu-open");
    const body = document.getElementsByTagName("BODY")[0];
    const html = document.documentElement;
    body.classList.remove("no-scroll");
    html.classList.remove("no-scroll");
  }

  noScroll() {
    const body = document.getElementsByTagName("BODY")[0];
    const html = document.documentElement;
    body.classList.toggle("no-scroll");
    html.classList.toggle("no-scroll");
  }

  closeModal() {
    this.setState({
      modalType: "",
      showModal: false
    });
  }

  showModal(type) {
    this.closeMobMenu();
    this.setState({
      modalType: type,
      showModal: !this.state.showModal
    });
  }

  renderModal() {
    const { modalType, userDetail } = this.state;
    switch (modalType) {
      case "login":
        return <LoginModal state={this.modalValues()}></LoginModal>;
      case "signup":
        return <SignUpModel state={this.modalValues()}></SignUpModel>;
      case "reset":
        return (
          <ResetPasswordModal state={this.modalValues()}></ResetPasswordModal>
        );
      case "official":
        return (
          <WelcomeOfficialModal
            state={this.modalValues()}
            user={userDetail}
          ></WelcomeOfficialModal>
        );
      case "review":
        return (
          <RegistrationSuccessModal
            state={this.modalValues()}
          ></RegistrationSuccessModal>
        );
    }
  }

  modalValues() {
    return {
      visible: this.state.showModal,
      toggleModal: () => this.closeModal(),
      showModal: e => this.showModal(e)
    };
  }

  renderHamburgerMenu() {
    return (
      <div id="mob-menu" className="mob-menu d-none">
        <form
          className="searchbox"
          onSubmit={e => {
            e.preventDefault();
            this.onVesselSearch();
          }}
        >
          <div className="search-container">
            <input
              type="search"
              placeholder={phrases["Find a vessel/company"]}
              className="form-control"
              value={this.state.term}
              onChange={e => this.setState({ searchKeyword: e.target.value })}
            />
            <i className="fa fa-search" aria-hidden="true" />
          </div>
        </form>
        <ul className="nav-sub-menu">
          <li>
            <a
              href="/fishermen-story"
              onClick={e => {
                this.toggleMobMenu();
              }}
            >
              {phrases["STORY"]}
            </a>
          </li>
          <li>
            <a
              href="/about"
              onClick={e => {
                this.toggleMobMenu();
              }}
            >
              {phrases["ABOUT"]}
            </a>
          </li>
          <li>
            <a
              href="/contact"
              onClick={e => {
                this.toggleMobMenu();
              }}
            >
              {phrases["CONTACT"]}
            </a>
          </li>
          <li>
            <a
              href="/info/request-or-submit"
              onClick={e => {
                this.toggleMobMenu();
              }}
            >
              {phrases["SUBMIT / REQUEST INFO"]}
            </a>
          </li>
        </ul>
      </div>
    );
  }

  renderNotificationIcon() {
    const { showNotif, isLoggedIn } = this.state;

    const dummyData = [
      {
        name: "Vessel 1",
        description:
          "Notification for this EEZ. Click here to see full details",
        dateAdded: "5 Days Ago"
      }
    ];

    const renderNotif = dummyData.length
      ? dummyData.map(notification => {
          return (
            <div className="notif-container">
              <span className="notif-img"> EEZ </span>
              <div className="notif-info">
                <span> {notification.name}</span>
                <span> {notification.description} </span>
                <span>
                  {" "}
                  <i class="far fa-clock"></i> {notification.dateAdded}{" "}
                </span>
              </div>
            </div>
          );
        })
      : "";
    return (
      <div className="notification-selector">
        <i
          className="fas fa-bell notification-icon"
          onClick={() =>
            isLoggedIn ? this.toggleNotification() : this.showModal("signup")
          }
        >
          {isLoggedIn && <span>{dummyData.length} </span>}
        </i>
        <div className={`notification-container ${showNotif ? "active" : ""}`}>
          <span className="notif-content-title">
            {" "}
            {dummyData.length ? "Notifcation" : "No Notifications, yet."}
          </span>
          {renderNotif}
        </div>
      </div>
    );
  }

  renderLanguageIcon() {
    const { showToggle } = this.state;
    return (
      <div className="language-container">
        <a
          class={`drop fas fa-globe`}
          onClick={e => {
            e.stopPropagation();
            this.toggleLanguage();
          }}
        />
        <div class={`container-menu ${showToggle ? "active" : ""}`}>
          <ul>{this.getLanguages()}</ul>
        </div>
      </div>
    );
  }

  renderLoginIcon() {
    const { showUser, isLoggedIn, userDetail } = this.state;
    return isLoggedIn ? (
      <div className="user-container">
        <a
          className="btn-user"
          onClick={e => {
            e.stopPropagation();
            this.setState({ showUser: !showUser, showToggle: false });
          }}
        >
          <i class="fas fa-user-circle"></i>
          <p>{`Welcome, ${userDetail.first_name}!`}</p>
        </a>
        {showUser && (
          <div className={`container-user-menu`}>
            <ul>
              {userDetail.is_superuser && (
                <li onClick={() => (window.location = "/admin/login")}>
                  {" "}
                  Admin Dashboard
                </li>
              )}
              {userDetail.is_superuser && (
                <li onClick={() => (window.location = "/admin/upload-incidents")}>
                  {" "}
                  Upload Incidents
                </li>
              )}
              <li onClick={() => (window.location = "/account-detail")}>
                {phrases["Account"]}
              </li>
              <li onClick={this.userLogout}>{phrases["Logout"]}</li>
            </ul>
          </div>
        )}
      </div>
    ) : (
      <button className="btn-login" onClick={() => this.showModal("login")}>
        {phrases["Login"]}
      </button>
    );
  }

  userLogout() {
    var csrftoken = Cookies.get("csrftoken");

    fetch(`/api/rest-auth/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken
      }
    })
      .then(response => response.json())
      .then(response => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userDetail");
        window.location = "/";
      });
  }

  render() {
    return (
      <header className="iuu-header">
        {this.renderModal()}
        {this.renderHamburgerMenu()}
        <div className="container-fluid">
          <div className="row iuu-header-row">
            <div className="col-left">
              <a
                href="/"
                className="logo"
                onClick={e => {
                  this.closeMobMenu();
                }}
              >
                <img src={logo} alt="IUU logo" />
              </a>
            </div>

            <div className="col-right">
              <div className="right-column-mobile-header d-none">
                <div className="icon-container">
                  {/* {this.renderNotificationIcon()} */}
                  {this.renderLanguageIcon()}
                  {this.renderLoginIcon()}
                </div>
                <div
                  className="hamburger d-flex flex-direction-column"
                  onClick={() => this.toggleMobMenu()}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div></div>
              <div className="right-column">
                <div className="right-column-header">
                  <div className="searchbox-container">
                    <div className="icon-container">
                      {/* {this.renderNotificationIcon()} */}
                      {this.renderLanguageIcon()}
                      {this.renderLoginIcon()}
                    </div>
                    <form
                      className="searchbox"
                      onSubmit={e => {
                        e.preventDefault();
                        this.onVesselSearch();
                      }}
                    >
                      <div className="search-container">
                        <input
                          type="search"
                          placeholder={phrases["Find a vessel/company"]}
                          className="form-control"
                          value={this.state.term}
                          onChange={e =>
                            this.setState({ searchKeyword: e.target.value })
                          }
                        />
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </form>
                  </div>

                  <h1
                    className="mobile-menu"
                    onClick={() => this.onNavBarToggle()}
                  >
                    <span>
                      <i className="fa fa-bars" />
                    </span>
                  </h1>
                  <div
                    className="navigation"
                    id="mySidenav"
                    style={this.state.menuOpen ? "width: 100%" : "width: 0"}
                  >
                    <a
                      href="javascript:void(0)"
                      className="closebtn"
                      onClick={() => this.onNavBarToggle()}
                    >
                      &times;
                    </a>
                    <ul className="nav-sub-menu">
                      <li>
                        <a href="/fishermen-story">{phrases["STORY"]}</a>
                      </li>
                      <li>
                        <a href="/about">{phrases["ABOUT"]}</a>
                      </li>
                      <li>
                        <a href="/contact">{phrases["CONTACT"]}</a>
                      </li>
                      <li>
                        <a href="/info/request-or-submit">
                          {phrases["SUBMIT / REQUEST INFO"]}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
