import { h, Component } from "preact";
import banner from "../../../static/images/page-background-island.png";
import API from "../../../classes/API";
import Footer from "../../Footer/footer";
import BasicProfileInfo from "./BasicProfileInfo/BasicProfileInfo";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      profile: [], // this holds the Profile details
      activeTab: "companyInfo"
    };
  }

  componentDidMount() {
    console.log(`loading details for Profile ${this.props.profileId}`);

    // Load Profile API data

    API.request(`/api/profiles/${this.props.profileId}/`).then(response => {
      this.setState({
        profile: response
      });
    });
  }

  // TABLES RENDERING FUNCTIONS ========================================

  onChangeActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  isActive(tab) {
    return this.state.activeTab === tab ? true : false;
  }

  onHandleProfilePanel() {
    // This function is responsible for handling the main display panel

    switch (this.state.activeTab) {
      case "companyInfo":
        return <BasicProfileInfo profile={this.state.profile} />;

      // case "flagHistory":
      //   return <FlagHistory Profile={this.state.Profile} />;

      // case "ownerHistory":
      //   return <OwnerHistory Profile={this.state.Profile} />;

      // case "incidents":
      //   return <IncidentsList Profile={this.state.Profile} />;

      // case "infraction-info":
      //   return <InfractionInfo Profile={this.state.Profile} />;
    }
  }

  // end tabs =================================

  render() {
    return (
      <div className="vessel company-profile">
        <div className="banner">
          <div className="mobile-banner-text">{phrases["COMPANY PROFILE"]}</div>

          <img src={banner} alt="costal image" />
          <a href="/" className="back-btn">
            <i className="fa fa-caret-left" aria-hidden="true" />{" "}
            {phrases["Back to Map"]}
          </a>
        </div>

        <div className="middle-sec">
          <h3>{phrases[""]}COMPANY PROFILE</h3>
          <div className="incident-content">
            <div className="row vessels-info-row">
              <div className="col-lg-3 col-md-4 padding">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={
                      this.isActive("companyInfo")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected={this.isActive("companyInfo")}
                    onClick={() => this.onChangeActiveTab("companyInfo")}
                  >
                    {phrases["Company Info"]}
                  </a>
                </div>
              </div>

              <div className="ui text col-md-8 col-sm-12 mobile-table-container">
                {this.onHandleProfilePanel()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Profile;
