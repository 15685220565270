export default class API {
  static getConfig() {
    return {
      env: {
        host: process.env.host
      }
    };
  }

  static request(url, method) {
    return fetch(`${API.getConfig().env.host}${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
      .then(response => response.json())
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  static post(url, method, body) {
    return fetch(`${API.getConfig().env.host}${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
}
