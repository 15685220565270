import { h, Component } from "preact";

import VesselTable from "../VesselTable";

class InfractionInfo extends Component {
  render() {
    const {
      year,
      offense,
      offense_category,
      eez
    } = this.props.vessel.incidents[0];

    return (
      <div className="basic-info">
        <VesselTable
          table={{
            title: phrases["Infraction Information"],
            columns: [phrases["Year"], phrases["Offense"], phrases["Offense Category"], "EEZ"],
            rows: [
              [
                year ? year : "-",
                offense ? offense : "-",
                offense_category ? offense_category : "-",
                eez.name ? eez.name : "-"
              ]
            ]
          }}
        />
      </div>
    );
  }
}

export default InfractionInfo;
