import { h, Component } from "preact";
import banner from "../../static/images/page-background-island.png";
import Footer from "../Footer/footer";
import AccountDetail from "./AccountDetail";

class Account extends Component {
  constructor() {
    super();
    this.state = {
      vessel: [], // this holds the vessel details
      activeTab: "accountDetail"
    };
  }

  componentDidMount() {}

  onChangeActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  isActive(tab) {
    return this.state.activeTab === tab ? true : false;
  }

  renderPage() {
    // This function is responsible for handling the main display panel

    switch (this.state.activeTab) {
      case "accountDetail":
        return <AccountDetail vessel={this.state.vessel} />;
    }
  }

  render() {
    return (
      <div className="vessel">
        <div className="banner">
          <div className="mobile-banner-text">{phrases["Account Details"]}</div>

          <img src={banner} alt="costal image" />
          <a href="/" className="back-btn">
            <i className="fa fa-caret-left" aria-hidden="true" />{" "}
            {phrases["Back to Map"]}
          </a>
        </div>

        <div className="middle-sec">
          <h3>{phrases["Account Details"]}</h3>
          <div className="incident-content">
            <div className="row vessels-info-row">
              <div className="col-lg-3 col-md-4 padding">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={
                      this.isActive("accountDetail")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected={this.isActive("accountDetail")}
                    onClick={() => this.onChangeActiveTab("accountDetail")}
                  >
                    {phrases["Account Information"]}
                  </a>
                </div>
              </div>

              <div className="ui text col-md-8 col-sm-12 mobile-table-container">
                {" "}
                {this.renderPage()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Account;
