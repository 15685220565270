import { h, Component } from "preact";
import VesselTable from "../VesselTable";

class PreviousNames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousNames: []
    };
  }

  generatePreviousName() {
    const vesselInfo = Object.entries(
      this.props.vessel
    ).map(([key, value]) => ({ key, value }));
    const filter = vesselInfo.find(field => {
      return field.key == "previous_names";
    });

    const filteredTable = filter.value.split(",");

    const getColumn =
      filteredTable[0] != ""
        ? filteredTable.map(string => {
            var regExp = /\(([^)]+)\)/;
            var regResult = regExp.exec(string);
            var date = regResult ? regResult[1] : "N/A";
            var vesselName = string.split("(")[0];
            return [vesselName, date];
          })
        : [];

    const table = {
      title: "Previous Names",
      columns: ["Previous Name", "Date"],
      rows: getColumn
    };

    return <VesselTable table={table} />;
  }

  render() {
    const renderPreviousNames = this.props.vessel.previous_names ? (
      this.generatePreviousName()
    ) : (
      <h1>{phrases["Sorry, There are no Previous names for this Vessel!"]}</h1>
    );
    return <div className="basic-info">{renderPreviousNames}</div>;
  }
}

export default PreviousNames;
