import { h, Component } from "preact";
import logo from "../../static/images/favicon-32x32.png";

export default class IUUModal extends Component {
  constructor() {
    super();
    this.state = {
      visible: true
    };
  }

  componentDidMount() {
    //take value that's already on localstorage

    // console.log(localStorage.getItem("iuu-modal"));

    //first check if localstorage has iuu-modal data
    if (JSON.parse(localStorage.getItem("iuu-modal")) == false) {
      this.setState({
        visible: false
      });
    }
  }

  onCloseModal() {
    //register on localstorage that user closed modal, so it doesn't bother him anymore
    localStorage.setItem("iuu-modal", false);

    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return this.state.visible ? (
      <div>
        <div className="iuu-modal-shadow"> </div>

        <div className="iuu-modal">
          <i
            onClick={() => this.onCloseModal()}
            className="far fa-times-circle close-btn"
          />
          <div class="new-modal-content">
            <span class="context-text">
                Search <br/> for the history <br/> of reported crimes <br/> in the world's oceans.
            </span>
          </div>
        </div>
      </div>
    ) : null;
  }
}
