import { h, Component } from "preact";
import Helper from "../../../classes/Helper";

class BasicInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onRenderVesselInfoItem(title, description) {
    return (
      <a className="item">
        <i className="fas fa-caret-right" />
        <div className="content">
          <div className="header">{title}:</div>
          <div className="description">{description}</div>
        </div>
      </a>
    );
  }

  onRenderVesselInfoItems() {
    const vesselInfo = Object.entries(
      this.props.vessel
    ).map(([key, value]) => ({ key, value }));

    return vesselInfo.map((data, i) => {
      //remove .0 from number values
      if (data.value) {
        if (data.value.toString().includes(".0")) {
          data.value = data.value.replace(".0", "");
        }
      }

      if (data.key === "mmsi" && !data.value) {
        data.value = " "; //leave MMSSI info blank when there's no info
      }

      if (data.value) {
        if (data.key === "owners") {
          data.key = "Last known owner(s)";
          if (!data.value.length) {
            data.value = "No data found";
          }

          //If there's some data about the owners
          if (!data.value.includes("No data found")) {
            const ownerNames = data.value
              .map(owner => `${owner.name} (${owner.profile_type})`)
              .join(", ");
            data.value = ownerNames;
          }
        }

        if (data.key === "incidents") {
          data.value = data.value.join(", ");
        } else if (data.key != "id") {
          return this.onRenderVesselInfoItem(
            Helper.onBeautifyTitle(data.key),
            data.value
          );
        }
      }
    });
  }

  render() {
    return (
      <div className="basic-info">
        <h1>{this.props.vessel.name}</h1>
        <div className="ui divider" />
        <div className="ui list">
          {this.props.vessel ? this.onRenderVesselInfoItems() : "Loading..."}
        </div>
      </div>
    );
  }
}

export default BasicInfo;
