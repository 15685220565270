import { h, Component } from "preact";
import API from "../../classes/API";
import Cookies from "js-cookie";

class SignUpModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      orgPhone: "",
      orgEmail: "",
      orgAddress: "",
      orgName: "",
      isRegister: false,
      messages: [],
      isOfficial: false
    };
    this.registrationValidation = this.registrationValidation.bind(this);
  }

  componentDidMount() {}

  getRegistrationData() {
    const { email, password, confirmPassword } = this.state;

    return {
      email: email,
      password1: password,
      password2: confirmPassword,
      username: email
    };
  }

  getOtherUserData() {
    const {
      firstName,
      lastName,
      orgPhone,
      email,
      orgAddress,
      orgName,
      isOfficial
    } = this.state;
    const returnData = isOfficial
      ? {
          first_name: firstName,
          last_name: lastName,
          organization_name: orgName,
          organization_email: email,
          organization_phone: orgPhone,
          organization_address: orgAddress,
          is_official: isOfficial
        }
      : {
          first_name: firstName,
          last_name: lastName,
          is_official: isOfficial
        };
    return returnData;
  }

  patchUserAPI() {
    const { email } = this.state;
    API.request(`/api/user/details/${email}`, "GET").then(response => {
      API.post(
        `/api/users/${response.id}/`,
        "PATCH",
        this.getOtherUserData()
      ).then(response => {
        this.setState({ loading: false });
      });
    });
  }

  redirectNextModal() {
    const { toggleModal, showModal } = this.props.state;
    this.patchUserAPI();
    toggleModal();
    showModal("review");
  }

  registrationValidation() {
    setTimeout(() => {
      const {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        isOfficial,
        orgName,
        orgAddress,
        orgPhone
      } = this.state;
      const messages = [];

      if (
        email !== "" &&
        password !== "" &&
        confirmPassword !== "" &&
        fullName !== ""
      ) {
        if (
          !this.validateEmail(email) ||
          password.length < 8 ||
          password !== confirmPassword
        ) {
          if (!this.validateEmail(email)) {
            messages.push("You entered an invalid Email");
          }
          if (password.length < 8) {
            messages.push(
              phrases["The password must be at least 8 characters."]
            );
          } else {
            if (password !== confirmPassword) {
              messages.push(phrases["The password you entered did not match."]);
            }
          }
        } else {
          API.post(
            `/api/rest-auth/registration/`,
            "POST",
            this.getRegistrationData()
          ).then(response => {
            const isSuccess =
              response.hasOwnProperty("key") ||
              response.hasOwnProperty("detail");
            isSuccess
              ? response["detail"]
                ? response["detail"].includes("CSRF Failed")
                  ? this.logoutExistingUser()
                  : this.redirectNextModal()
                : this.redirectNextModal()
              : Object.values(response).map(values => {
                  messages.push(values[0]);
                });

            this.validateMessage(messages);
          });
        }
      } else {
        if (email.length === 0) {
          messages.push(phrases["Please fill in your Email."]);
        }
        if (firstName.length === 0) {
          messages.push("Please fill in your First Name.");
        }
        if (lastName.length === 0) {
          messages.push("Please fill in your Last Name.");
        }
        if (password.length === 0) {
          messages.push(phrases["Please fill in your Password."]);
        }
        if (confirmPassword.length === 0) {
          messages.push(phrases["Please confirm your password."]);
        }
        if (isOfficial) {
          if (orgName.length === 0) {
            messages.push("Please fill your Organization Name.");
          }
          if (orgPhone.length === 0) {
            messages.push("Please fill your Organization Phone.");
          }
          if (orgAddress.length === 0) {
            messages.push("Please fill your Organization Address.");
          }
        }
      }

      this.validateMessage(messages);
    }, 1000);
  }

  validateMessage(messages) {
    const isError = messages.length != 0 ? true : false;
    const isLoading = isError ? false : true;

    this.setState({
      messages: messages,
      isRegister: isError,
      loading: isLoading
    });
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  logoutExistingUser() {
    var csrftoken = Cookies.get("csrftoken");

    fetch(`/api/rest-auth/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken
      }
    })
      .then(response => response.json())
      .then(response => {
        this.registrationValidation();
      });
  }

  render() {
    const {
      isRegister,
      messages,
      loading,
      isOfficial,
      orgAddress,
      orgName,
      orgEmail,
      orgPhone,
      email
    } = this.state;

    const { visible, toggleModal } = this.props.state;

    const renderErrorMessage = messages.length
      ? messages.map(message => {
          return <li className="error-item">{message}</li>;
        })
      : "";

    const renderOrganizationDetail = isOfficial ? (
      <div>
        <div className="twelve columns">
          <label for="orgName">Organization Name </label>
          <input
            class="u-full-width"
            type="text"
            placeholder="John Doe Org"
            id="orgName"
            name="orgName"
            value={orgName}
            onChange={e => {
              this.setState({ orgName: e.target.value });
            }}
          />
        </div>
        <div>
          <div className="six columns">
            <label for="orgEmail">Organization Email</label>
            <input
              class="u-full-width"
              type="text"
              placeholder="john@johndoeorg.com"
              id="orgEmail"
              name="orgEmail"
              value={email}
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
            />
          </div>
          <div className="six columns">
            <label for="orgPhone">Organization Phone</label>
            <input
              class="u-full-width"
              type="text"
              placeholder="241-2626"
              id="orgPhone"
              name="orgPhone"
              value={orgPhone}
              onChange={e => {
                this.setState({ orgPhone: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="twelve columns">
          <label for="orgAddress">Organization Address </label>
          <input
            class="u-full-width"
            type="text"
            placeholder="Block 39, Sai Luen Court, Aberdeen, New Territories"
            id="orgAddress"
            name="orgAddress"
            value={orgAddress}
            onChange={e => {
              this.setState({ orgAddress: e.target.value });
            }}
          />
        </div>
      </div>
    ) : (
      ""
    );

    return visible ? (
      <div>
        <div className="iuu-modal-shadow"> </div>

        <div className="iuu-modal">
          <i onClick={toggleModal} className="far fa-times-circle" />
          <div className="iuu-modal-content">
            <div className="modal-content">
              <div className="sign-up-container">
                <span className="sign-up-greeting">
                  Create a SPYGLASS Account for Public Officials.
                </span>
                <div
                  className={`content-form form-activate`}
                  disabled={loading}
                >
                  <div className="org-divider">
                    <span>
                      Are you are a member of an enforcement agencry or a
                      governmental organization?{" "}
                    </span>
                    <input
                      type="checkbox"
                      class="toggle"
                      id="toggle"
                      checked={this.state.isOfficial}
                      onChange={() =>
                        this.setState({ isOfficial: !isOfficial })
                      }
                    />
                    <label for="toggle" id="organization-toggle">
                      <span class="on">Yes</span>
                      <span class="off">No</span>
                    </label>
                  </div>
                  <form className="sign-up-form">
                    <div>
                      <div className="six columns">
                        <label for="firstName">First Name</label>
                        <input
                          class="u-full-width"
                          type="text"
                          placeholder="John"
                          id="firstName"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={e => {
                            this.setState({ firstName: e.target.value });
                          }}
                        />
                      </div>
                      <div className="six columns">
                        <label for="fullName">Last Name</label>
                        <input
                          class="u-full-width"
                          type="text"
                          placeholder="Doe"
                          id="fullName"
                          name="fullName"
                          value={this.state.lastName}
                          onChange={e => {
                            this.setState({ lastName: e.target.value });
                          }}
                        />
                      </div>
                    </div>

                    {!isOfficial ? (
                      <div className="twelve columns">
                        <label for="email">{phrases["E-Mail"]}</label>
                        <input
                          class="u-full-width"
                          type="email"
                          placeholder="johndoe@mail.com"
                          id="email"
                          name="email"
                          value={this.state.email}
                          onChange={e => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                      </div>
                    ) : null}

                    {renderOrganizationDetail}

                    <div className="twelve columns">
                      <label for="origPassword">{phrases["Password"]}</label>
                      <input
                        class="u-full-width"
                        type="password"
                        placeholder="**********"
                        id="origPassword"
                        name="origPassword"
                        value={this.state.password}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                    </div>

                    <div className="twelve columns">
                      <label for="retypePassword">
                        {phrases["Re-type Password"]}
                      </label>
                      <input
                        class="u-full-width"
                        type="password"
                        placeholder="**********"
                        id="retypePassword"
                        name="retypePassword"
                        value={this.state.confirmPassword}
                        onChange={e => {
                          this.setState({ confirmPassword: e.target.value });
                        }}
                      />
                    </div>
                  </form>
                </div>

                <ul
                  className={`error-list ${isRegister ? "form-activate" : ""}`}
                >
                  {renderErrorMessage}
                </ul>

                <div className="btn-container">
                  <button
                    className={`btn-curve btn-next green ${
                      loading ? "btn-activate" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        loading: true,
                        isRegister: false
                      }),
                        this.registrationValidation();
                    }}
                  >
                    {loading && <i class="fa fa-circle-o-notch fa-spin"></i>}
                    {phrases["Sign UP"]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default SignUpModel;
