import { h, Component } from "preact";

class FishermanStory extends Component {
  constructor() {
    super();
    this.state = {
      stories: {}
    };
  }

  componentDidMount() {
    this.setState({ stories: this.props.vessel.incidents[0].fishermen_story });
  }

  render() {
    const { stories } = this.state;
    const renderStories = stories ? (
      <div>
        <h1> Fisherman Story Details</h1>
        <div className="story-row">
          <div> {stories.title}</div>
          <div> {stories.teaser} </div>
          <a
            onClick={() =>
              window.location.replace(`/fishermen-story/${stories.id}`)
            }
          >
            {" "}
            Read More
          </a>
        </div>
      </div>
    ) : (
      <h1>{phrases["Sorry, No Fisherman Story found!"]} </h1>
    );
    return (
      <div className="basic-info">
        <div className="story-wrapper"> {renderStories} </div>
      </div>
    );
  }
}

export default FishermanStory;
