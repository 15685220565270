import { h } from "preact";
import logo from "../../static/images/favicon-32x32.png";

const RegistrationSuccessModal = props => {
  const { visible, toggleModal } = props.state;

  const redirectLogin = () => {
    sessionStorage.setItem("forLogin", true);
    window.location.reload();
  };

  return visible ? (
    <div>
      <div
        className="iuu-modal-shadow"
        onLoad={setTimeout(() => {
          redirectLogin();
        }, 2000)}
      >
        {" "}
      </div>

      <div className="iuu-modal iuu-modal-small">
        <i onClick={() => redirectLogin()} className="far fa-times-circle" />
        <div className="msg-modal">
          <img className="logo-modal" src={logo}></img>
          {/* <h1> {phrases["Your registration was a success."]}</h1>
          <span> {phrases["You can now proceed to Login."]}</span> */}
          <h1>{phrases["Your registration was a success."]}</h1>
          <h3> Please verify your email address to login. </h3>
          <button onClick={() => redirectLogin()}>Confirm</button>
        </div>
      </div>
    </div>
  ) : (
    " "
  );
};

const WelcomeModal = props => {
  const { visible, toggleModal } = props.state;
  return visible ? (
    <div>
      <div className="iuu-modal-shadow"> </div>

      <div className="iuu-modal iuu-modal-small">
        <i onClick={toggleModal} className="far fa-times-circle" />
        <div className="msg-modal">
          <img className="logo-modal" src={logo}></img>
          <h1> Hello User, Welcome!</h1>
          <span> You can now proceed to Login.</span>
        </div>
      </div>
    </div>
  ) : (
    " "
  );
};

const WelcomeOfficialModal = props => {
  const { visible, toggleModal } = props.state;
  const user = props.user;

  const redirectToDashboard = () => {
    setTimeout(() => {
      window.location.href = "/account-detail";
    }, 2000);
  };
  return visible ? (
    <div>
      {redirectToDashboard()}
      <div className="iuu-modal-shadow"> </div>
      <div className="iuu-modal iuu-modal-small">
        <i onClick={toggleModal} className="far fa-times-circle" />
        <div className="msg-modal">
          <img className="logo-modal" src={logo}></img>
          <h2> SpyGlass Dashboard</h2>
          <h2>(for public officials)</h2>
          <h2>{`Welcome, ${user.email}`}</h2>
        </div>
      </div>
    </div>
  ) : (
    " "
  );
};

export { RegistrationSuccessModal, WelcomeModal, WelcomeOfficialModal };
