import { h, Component } from "preact";
import Footer from "../Footer/footer";
import API from "../../classes/API";

class StoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      story: {}
    };
    console.log(props);
  }

  componentDidMount() {
    const { storyId } = this.props;
    API.request(`/api/fishermen_stories`, "GET").then(response => {
      const story = response.find(story => {
        return story.id == storyId;
      });
      this.setState(
        {
          story
        },
        () => {}
      );
    });
  }

  render() {
    const { story } = this.state;
    return (
      <div>
        <div className="page contact">
          <div className="page-image-wrapper" />
          <main className="story-detail-container">
            <h1 className="story-title"> {story.title} </h1>
            <div class="summary-meta">
              {/* <span class="author">
                <i class="fa fa-user"></i>
                Dyhia Belhabib
              </span>
              <span class="timestamp">
                <i class="fa fa-clock-o"></i> 5 mins read
              </span> */}
            </div>

            <p className="story-content">{story.body}</p>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default StoryDetail;
