import { h, Component } from "preact";
import Helper from "../../../classes/Helper";
import VesselTable from "../VesselTable";

class OwnerHistory extends Component {
  componentDidMount() {
    console.log(this.props.vessel.owners);
  }

  // onRenderOwnerItem(owner) {
  //   const kv = Object.entries(owner).map(([key, value]) => ({ key, value }));

  //   return kv
  //     .filter(k => k.value)
  //     .map(elem => {
  //       return (
  //         <a class="item">
  //           <i class="fas fa-caret-right" />
  //           <div class="content">
  //             <div class="header">{Helper.parseKey(elem.key)}: </div>
  //             <div class="description">
  //               {typeof elem.value == "object"
  //                 ? elem.value["name"]
  //                 : elem.value}
  //             </div>
  //           </div>
  //         </a>
  //       );
  //     });

  //   console.log(kv);
  // }

  // onRenderOwnersList() {
  //   return this.props.vessel.owners.map(owner => {
  //     return (
  //       <div className="ui list">
  //         <div className="incident-title">
  //           {owner.name} - {owner.profile_type}
  //         </div>
  //         <hr />
  //         {this.onRenderOwnerItem(owner)}
  //       </div>
  //     );
  //   });
  // }

  onRenderOwnersTables() {
    return this.props.vessel.owners.map(owner => {
      const { name, profile_type, address, date, remarks } = owner;

      return (
        <VesselTable
          table={{
            title: `${name} ${profile_type ? `(${profile_type})` : ""}`,
            columns: [
              phrases["Name"],
              phrases["Profile Type"],
              phrases["Address"],
              phrases["Date"],
              phrases["Remarks"]
            ],
            rows: [
              [
                name ? name : "-",
                profile_type ? profile_type : "-",
                address ? address : "-",
                date ? date : "-",
                remarks ? remarks : "-"
              ]
            ]
          }}
        />
      );
    });
  }

  render() {
    if (!this.props.vessel.owners.length) {
      return (
        <div className="basic-info">
          <div className="content">
            <h1>{phrases["Sorry!"]}</h1>
            <p>{phrases["We have no data about the vessel owners."]}</p>
          </div>
        </div>
      );
    }

    return <div className="basic-info">{this.onRenderOwnersTables()}</div>;
  }
}

export default OwnerHistory;
