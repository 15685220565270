import { h, Component } from "preact";
import Footer from "../Footer/footer";
import RequestInfoForm from "./RequestInfoForm";

class RequestInfo extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="page">
          <div className="page-image-wrapper" />
          <main className="about request-info">
            <h1>{phrases["Request or Submit Information"]}</h1>
            <RequestInfoForm />
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default RequestInfo;
