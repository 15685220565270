import { h, Component } from "preact";
import API from "../../classes/API";
import logo from "../../static/images/favicon-32x32.png";
import banner1 from "../../static/images/placeholder2.svg";
import Cookies from "js-cookie";

class SignInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isSubmit: false,
      isSuccess: false,
      loading: false,
      messages: []
    };
  }

  componentDidMount() {}

  validateLogin() {
    const { email, password } = this.state;

    const loginBody = {
      email: email,
      password: password,
      username: email
    };

    const messages = [];

    setTimeout(() => {
      if (email.length != 0 && password.length != 0) {
        API.post(`/api/rest-auth/login/`, "POST", loginBody).then(response => {
          if (response.hasOwnProperty("key")) {
            const data = { token: response.key, email };
            this.getUserDetails(data);
            messages.push(
              "You have logged in Successfully. You are being redirected."
            );
          } else if (JSON.stringify(response) === "{}") {
            messages.push(
              "Login Failed. Please contact our administrator for assistance."
            );
          } else {
            Object.entries(response).map(value => {
              if (value[1].includes("CSRF Failed")) {
                this.logoutExistingUser();
                messages.push("Login Failed. Please try logging in again.");
              } else {
                messages.push(value[1]);
              }
            });
          }
          const isError = messages.length != 0 ? true : false;
          const isSuccess = response.hasOwnProperty("key") ? true : false;
          this.setState({
            isSuccess,
            isSubmit: isError,
            messages: messages,
            loading: false
          });
        });
      } else {
        if (email.length == 0) {
          messages.push(phrases["Please fill in your Email."]);
        }
        if (password.length == 0) {
          messages.push(phrases["Please fill in your Password."]);
        }
      }

      const isError = messages.length != 0 ? true : false;
      this.setState({
        isSubmit: isError,
        messages: messages,
        loading: false,
        isSuccess: false
      });
    }, 1000);
  }

  logoutExistingUser() {
    var csrftoken = Cookies.get("csrftoken");

    fetch(`/api/rest-auth/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken
      }
    })
      .then(response => response.json())
      .then(response => {});
  }

  getUserDetails(data) {
    setTimeout(() => {
      API.request(`/api/user/details/${data.email}/`, "GET").then(response => {
        data["id"] = response.id;
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userDetail", JSON.stringify(data));
        window.location.href = "/account-detail";
      }, 1000);
    });
  }

  render({}, { email, password, loading, messages, isSubmit, isSuccess }) {
    const { visible, toggleModal, showModal } = this.props.state;

    const redirectToRegister = () => {
      toggleModal();
      showModal("signup");
    };

    const redirectToForgotPassword = () => {
      toggleModal();
      showModal("reset");
    };

    const renderErrorMessage = messages.length
      ? messages.map(message => {
          return (
            <li className={`${isSuccess ? "success-item" : "error-item"}`}>
              {message}
            </li>
          );
        })
      : "";

    return visible ? (
      <div>
        <div className="iuu-modal-shadow"> </div>
        <div className="iuu-modal">
          <i onClick={toggleModal} className="far fa-times-circle" />
          <div className="iuu-modal-content">
            <div className="modal-content" disabled={loading}>
              <div className="modal-left-info">
                <img src={logo}></img>
                <span className="modal-greeting">
                  SPYGLASS Login for Public Official
                </span>

                <form>
                  <div className="twelve columns">
                    <label for="email">{phrases["E-Mail"]}</label>
                    <input
                      class="u-full-width"
                      type="email"
                      placeholder="johndoe@mail.com"
                      id="email"
                      name="email"
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </div>

                  <div className="twelve columns">
                    <label for="origPassword">{phrases["Password"]}</label>
                    <input
                      class="u-full-width"
                      type="password"
                      placeholder="**********"
                      id="origPassword"
                      name="origPassword"
                      value={password}
                      onChange={e =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                </form>

                <ul
                  className={`${isSuccess ? "success-list" : "error-list"} ${
                    isSubmit ? "form-activate" : ""
                  }`}
                >
                  {renderErrorMessage}
                </ul>

                <span>
                  {phrases["Forgot your password?"]}{" "}
                  <a href="#" onClick={() => redirectToForgotPassword()}>
                    {phrases["Click here."]}
                  </a>
                </span>
                <button
                  className={`btn-curve btn-next green ${
                    loading ? "btn-activate" : ""
                  }`}
                  onClick={() =>
                    this.setState({ isSubmit: false, loading: true }, () =>
                      this.validateLogin()
                    )
                  }
                >
                  {loading && <i class="fa fa-circle-o-notch fa-spin"></i>}
                  {phrases["SIGN IN"]}
                </button>
              </div>

              <div
                className="modal-right-info"
                style={`--background: url(${banner1});`}
              >
                <span className="modal-title">
                  Sign up for Public Official.
                </span>
                <span>
                  Sign up today to see more Incidents that are not available to
                  the public.
                </span>
                <button className="btn-curve" onClick={redirectToRegister}>
                  {phrases["Sign UP"]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}
export default SignInModal;
