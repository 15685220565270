import { h, Component } from "preact";
import countableLogo from "../../static/images/logo.svg";
import ecotrustLogo from "../../static/images/new-ecotrust-canada.png";
import Modal from "../UI/Modal";

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      languages: [
        { language: "English", value: "en" },
        { language: "French", value: "fr" },
        { language: "Portugese", value: "pt" },
        { language: "Spanish", value: "es" },
        { language: "Arabic", value: "ar" }
      ]
    };
  }

  changeLanguage(lang) {
    localStorage.setItem("currentLanguage", lang);
    window.location.reload(true);
  }

  onShowDonationModal() {
    console.log("show donation modal...");

    this.setState({
      showModal: !this.state.showModal
    });
  }

  onCloseModal() {
    console.log("triggering modal close");

    this.setState({
      showModal: !this.state.showModal
    });
  }

  render() {
    const { languages } = this.state;

    const renderLanguages = languages.length ? (
      languages.map((language, key) => {
        return (
          <div className="ui inverted link list" key={key}>
            <a
              href="#"
              className="item"
              onClick={() => this.changeLanguage(language.value)}
            >
              {language.language}
            </a>
          </div>
        );
      })
    ) : (
      <div> No Languages Available</div>
    );
    return (
      <div>
        <Modal
          modalSize="small"
          visible={this.state.showModal}
          onCloseModal={() => this.onCloseModal()}
        >
          <h1>{phrases["Donation"]}</h1>
          <p>
            {phrases["Please, specify your"]}{" "}
            <strong>{phrases["donation purpose"]}</strong>
            {phrases["on the next page."]}
          </p>
          <a
            href="http://ecotrust.ca/impact"
            target="_blank"
            className="modal-button"
          >
            {phrases["Proceed to Donation page"]}
          </a>
        </Modal>
        <footer className="ui inverted vertical footer segment">
          <div className="ui container">
            <div className="ui stackable inverted divided equal height stackable grid">
              <div className="three wide column">
                <h4 className="ui inverted header">{phrases["Provided by"]}</h4>
                <div className="ui inverted link list">
                  <div className="ui inverted link list">
                    <a href="http://ecotrust.ca/" className="item">
                      <img
                        src={ecotrustLogo}
                        alt="ecotrust brand"
                        className="ecotrust-logo"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="three wide column">
                <h4 className="ui inverted header">
                  {phrases["Our Services"]}
                </h4>
                <div className="ui inverted link list">
                  <a href="/contact" className="item">
                    {phrases["Contact Us"]}
                  </a>
                  <a href="#" className="item">
                    Vancouver Office: 425 Carrall St
                  </a>
                  <a href="#" className="item">
                    Prince Rupert Office: 515 3 Ave W
                  </a>
                </div>
              </div>

              <div className="three wide column">
                <h4 className="ui inverted header">{phrases["About"]}</h4>
                <div className="ui inverted link list">
                  <a href="http://ecotrust.ca/" className="item">
                    Ecotrust Canada
                  </a>
                  <a href="/about#idea" className="item">
                    {phrases["The Idea and how it merged"]}
                  </a>
                  <a href="/about#methods" className="item">
                    {phrases["Methods and Disclaimer"]}
                  </a>
                  <a href="/about#principal" className="item">
                    {phrases["The Principal Investigator"]}
                  </a>
                </div>
              </div>

              {/* Hidden Language Selector Footer
               <div className="three wide column">
                <h4 className="ui inverted header">Switch Language</h4>
                {renderLanguages}
              </div> */}

              <div className="three wide column">
                <h4 className="ui inverted header">{phrases["Information"]}</h4>
                <div className="ui inverted link list">
                  <a href="/info/request-or-submit" className="item">
                    {phrases["Submit/Request info"]}
                  </a>
                </div>
              </div>

              <div className="three wide column">
                <h4 className="ui inverted header">{phrases["Contribute"]}</h4>
                <div className="ui inverted link list">
                  <a
                    href="http://ecotrust.ca/impact"
                    className="item item-button"
                    onClick={e => {
                      e.preventDefault();
                      this.onShowDonationModal();
                    }}
                  >
                    {phrases["Donate"]}
                  </a>
                </div>
              </div>

              {/*<div className="three wide column">*/}
              {/*    <h4 className="ui inverted header">Services</h4>*/}
              {/*    <div className="ui inverted link list">*/}
              {/*        <a href="#" className="item">Banana Pre-Order</a>*/}
              {/*        <a href="#" className="item">DNA FAQ</a>*/}
              {/*        <a href="#" className="item">How To Access</a>*/}
              {/*        <a href="#" className="item">Favorite X-Men</a>*/}
              {/*    </div>*/}
              {/*</div>*/}
              {/*<div className="seven wide column">*/}
              {/*    <h4 className="ui inverted header">Footer Header</h4>*/}
              {/*    <p>Extra space for a call to action inside the footer that could help re-engage users.</p>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="built-with-love">
            <a href="http://countable.ca" target="_blank">
              {phrases["Built with love by"]}
              <img
                src={countableLogo}
                className="countable-logo"
                alt="Counexport default Footer;


import React, {Component} from 'react';
able Logo"
              />
            </a>
          </div>
        </footer>
      </div>
    );
  }
}
