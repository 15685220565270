import { h, Component } from "preact";
import Footer from "../Footer/footer";
import dyhiaPicture from "../../static/images/dyhia-picture.png";
import ecotrustLogo from "../../static/images/new-ecotrust-canada.png";

class About extends Component {
  constructor() {
    super();
    this.state = {
      filtersOpen: false
    };
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  componentDidMount() {
    this.scrollToSection();
  }

  componentDidUpdate() {
    this.scrollToSection();
  }

  scrollToSection() {
    const { hash } = window.location;
    window.setTimeout(() => {
      if (hash) {
        var element = document.querySelector(hash);
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }, 500);
  }

  render() {
    return (
      <div>
        <div className="page">
          <div className="page-image-wrapper" />
          <main className="about">
            <div className="ecotrust-logo">
              <img src={ecotrustLogo} alt="ecotrust logo" />
              <div className="mission-container">
                <span>{phrases["OUR MISSION"]}</span>
                <p>
                  {
                    phrases[
                      "Designing economic alternatives that benefit people in the places they call home"
                    ]
                  }
                </p>
              </div>
            </div>

            <h1>{phrases["Introduction"]}</h1>

            <p>
              {
                phrases[
                  "Illegal fishing and other forms of crimes committed by and onboard fishing vessels at sea, affect ecosystems, people, and their communities. Fishing crimes jeopardize livelihoods and food security of millions of people around the globe. Spyglass is a cooperative platform that publishes information from the Criminal Record of Fishing Vessels © 2018 Dyhia Belhabib, and allows users to have access to the criminal history of individual large-scale fishing vessels and their companies, and small-scale fishing vessels, in support of local governments, Monitoring, Control, and Surveillance agencies, fishing communities, and the public."
                ]
              }
            </p>

            <h1>{phrases["Objective"]}</h1>

            <p>
              {
                phrases[
                  "Despite very high ecological, social and economic costs, issues with illegal fishing are often intensified by the lack of human and financial resources to monitor coastal waters. Often, developing countries’ surveillance efforts become handicapped by the lack of financial resources after the departure of e.g. a big funder, or the termination of a project. We are building a pioneering collaborative initiative that will allow surveillance departments, NGOs and governments to focus their surveillance efforts on high risk vessels, to more effectively sanction those vessels, and therefore increase the efficiency, cost effectiveness and transparency of their monitoring efforts. I-Sea Fisheries, under which spyglass.fish is created, is a project funded by the Paul M. Angell Family Foundation will establish a worldwide community platform that addresses this need allowing some of the most vulnerable countries in the world to sustainably recover their Monitoring Control and Surveillance Costs, and systematically reduce illegal fishing and criminal activity of large scale vessels at-sea."
                ]
              }
            </p>

            <h1 id="idea">{phrases["The idea and how it emerged"]}</h1>
            <p>
              {
                phrases[
                  "Opening up information to the criminal history of ocean going vessels has the potential to dramatically improve the wellbeing of coastal communities through a wide range of impacts from increasing local control of resource management, to reducing the loss of local incomes and jobs to industrial illegal fishing. It also could inform subsidy ban to high risk vessels and their companies. For example, the Senegalese Department of Surveillance having access to information on the past criminal activities of vessels in their waters, sanctioned every vessel for under-reporting vessel capacity, and refused fishing licences to these high risk vessels. Under-reporting of vessel capacity often goes unsanctioned. Cases such as this one are key to explain how cooperation and shared information can dramatically improve a countries’ ability to protect its waters from illegal fishing and other related crimes. Knowing the power of shared data to support the wellbeing of communities, Ecotrust Canada under the leadership and vision of Dr. Dyhia Belhabib, has designed the I-Sea Fisheries project to enable easy, affordable, and open data sharing on the criminal history of fishing vessels."
                ]
              }
            </p>

            <h1 id="methods">{phrases["Methods and Disclaimer"]}</h1>
            <h2>
              {
                phrases[
                  "Information extracted from the Criminal Record of Fishing Vessels © 2018 Dyhia Belhabib"
                ]
              }
            </h2>
            <p>
              {
                phrases[
                  "The information and data contained in this record has been produced with the author’s reasonable efforts, and the incident reports provided are based on the sources provided, and are not verified, and hence the author does not assume responsibility over erroneous information. The sources are believed to be reliable and accurate, no warranty, expressed or implied, is made regarding adequacy, completeness, legality, reliability, accuracy or usefulness of any information. The information contained herein is provided on an ‘as is’ basis and collected sources that are believed to be reliable (literature, media, government agencies, witness testimonies, and confidential informants). The author does not guarantee that the information on this record is 100% accurate or up to date, particularly for alleged cases, including data concerning vessel information. Changes and updates will be made as information becomes available. All the information and content in this record is sourced appropriately, and as many releasable sources are made available and accessible, as possible."
                ]
              }
            </p>

            <h2>
              {
                phrases[
                  "The Criminal Record of Fishing Vessels © 2018 Dyhia Belhabib"
                ]
              }
            </h2>
            <p>
              {
                phrases[
                  "The Criminal Record of Fishing vessels is a database that includes nearly 4,000 vessels entries and 1,200 company entries, all linked to Illegal, Unreported, and Illegal fishing, or other types of transnational crimes. Spyglass.fish is a portal that publishes the information from the criminal record that is meant to be public. The database contains various offences between the period 2010 to present and categorizes them into 10 main categories:"
                ]
              }
            </p>

            <div className="offense-categories">
              <div className="offense">
                <div className="offense-title">
                  {phrases["Species and bycatch related"]}
                </div>
                <div className="offense-description">
                  {
                    phrases[
                      "When an offence is related to bycatch, or discarding practices that are illegal, to a species size, prohibited species, etc."
                    ]
                  }
                </div>
              </div>

              <div className="offense">
                <div className="offense-title">{phrases["Gear Related"]}</div>
                <div className="offense-description">
                  {
                    phrases[
                      "When the gear type is not conform to the standards, or illegal"
                    ]
                  }
                </div>
              </div>

              <div className="offense">
                <div className="offense-title">
                  {phrases["Human rights and labor abuse"]}
                </div>
                <div className="offense-description">
                  {
                    phrases[
                      "All forms of abuse (to crew, observers, etc.), slavery, human trafficking, murder, underpayment, over crewing, overworking conditions, etc."
                    ]
                  }
                </div>
              </div>
              <div className="offense">
                <div className="offense-title">{phrases["Quota related"]}</div>
                <div className="offense-description">
                  {
                    phrases[
                      "Fishing beyond quota, mis-reporting quota species catch, cheating on quota by replacing one species with the other, etc."
                    ]
                  }
                </div>
              </div>

              <div className="offense">
                <div className="offense-title">{phrases["Other"]}</div>
                <div className="offense-description">
                  {
                    phrases[
                      "Other IUU related activities, or unspecified offences"
                    ]
                  }
                </div>
              </div>

              <div className="offense">
                <div className="offense-title">{phrases["Smuggling"]}</div>
                <div className="offense-description">
                  {
                    phrases[
                      "Smuggling of wildlife, illicit drugs, weapons, etc."
                    ]
                  }
                </div>
              </div>

              {/* <div className="offense">
                <div className="offense-title">{phrases["Bycatch and Species related"]}</div>
                <div className="offense-description">
                  {phrases["Fishing for a prohibited species"]}
                </div>
              </div> */}

              <div className="offense">
                <div className="offense-title">{phrases["Unauthorized"]}</div>
                <div className="offense-description">
                  {
                    phrases[
                      "Fishing without a valid license, permit, or illegal transshipment"
                    ]
                  }
                </div>
              </div>

              <div className="offense">
                <div className="offense-title">
                  {phrases["Under-reporting"]}
                </div>
                <div className="offense-description">
                  {
                    phrases[
                      "Under-reporting of catches or effort (such as fishing capacity)"
                    ]
                  }
                </div>
              </div>

              <div className="offense">
                <div className="offense-title">
                  {phrases["Zone and/or Season related"]}
                </div>
                <div className="offense-description">
                  {
                    phrases[
                      "Fishing in a prohibited zone, and/or in a closed area"
                    ]
                  }
                </div>
              </div>
            </div>

            <h1 id="principal">{phrases["The Principal Investigator"]}</h1>

            <p>
              {phrases["Dr."]}
              <a
                href="http://ecotrust.ca/staff/dyhia-belhabib/"
                target="_blank"
              >
                Dyhia Belhabib
              </a>
              {
                phrases[
                  "is a Program Manager at Ecotrust Canada, and the Principal Investigator for I-Sea Fisheries. She also acts as an advisor for a few research projects at the University of British Columbia where she used to be a Research Associate and Fisheries Scientist. Dr. Belhabib works on fisheries economics, conservation, equity, and food security, community engagement, fishing rights, and First Nation fisheries issues such as illegal fishing, fisheries agreements, and lack of fisheries data. Her work focuses greatly on adding transparency and insight through research on fisheries in Canada and abroad. She works closely with national and regional bodies, governments, non-governmental and professional organizations towards the uptake of scientific findings. She is committed to adding socio-economic and geographic diversity in the field and has worked on building local capacity and strong collaborations with many partners around the world by conducting numerous data research and analysis training activities, notably in Africa."
                ]
              }
            </p>
            <p>
              {
                phrases[
                  "Dr. Belhabib completed her PhD in Resource Management and Environmental Studies at the Fisheries Centre, UBC, in 2014, just hours before she had her baby. As a researcher she focuses on one of the most problematic fisheries regions in the world, Africa. She has reconstructed fisheries catches of all sectors for 22 countries of West Africa, and has assessed the economic and societal importance of small-scale fisheries in the region and how their resilience and performance were affected by illegal fisheries, climate change, and lack of adequate data. Her research and engagement work was translated into major policy changes in countries of West Africa and were featured in major media outlets such as the New York Times ("
                ]
              }
              <a
                href="https://www.nytimes.com/2017/05/03/opinion/china-wants-fish-so-africa-goes-hungry.html"
                target="_blank"
              >
                {phrases["Story 1"]}
              </a>
              ,{" "}
              <a
                target="_blank"
                href="https://www.nytimes.com/2017/04/30/world/asia/chinas-appetite-pushes-fisheries-to-the-brink.html"
              >
                {phrases["Story 2"]}
              </a>
              ),{" "}
              <a
                href="https://www.nature.com/news/fisheries-eyes-on-the-ocean-1.17121"
                target="_blank"
              >
                {phrases["Nature"]}
              </a>
              ,{" "}
              <a
                href="https://www.sciencemag.org/news/2016/01/official-statistics-understate-global-fish-catch-new-estimate-concludes"
                target="_blank"
              >
                {phrases["Science"]}
              </a>
              , {phrases["Radio Canada"]} (
              <a
                href="http://ici.radio-canada.ca/emissions/phare_ouest/2015-2016/archives.asp?date=2017-06-08"
                target="_blank"
              >
                {phrases["Story 1"]}
              </a>
              ,{" "}
              <a
                href="http://ici.radio-canada.ca/emissions/phare_ouest/2015-2016/archives.asp?date=2015-05-28"
                target="_blank"
              >
                {phrases["Story 2"]}
              </a>
              ),{" "}
              {
                phrases[
                  "and many others. Dr. Belhabib has over 40 peer reviewed and non-peer reviewed publications, and is an active blogger (read her"
                ]
              }
              <a
                href="http://researcherdiaries.com/dyhia-belhabib-blog-fisheries-vancouver-UBC-research-diaries-sea-ocean/diaries/"
                target="_blank"
              >
                {phrases["stories"]}
              </a>
              ).
            </p>
            <p>
              {
                phrases[
                  "Dr. Belhabib is also on the board of the National Centre for Fisheries and Aquaculture Research of Algeria, and the FishTracker initiative based in London."
                ]
              }
            </p>

            <div className="bottom-profile">
              <img src={dyhiaPicture} alt="Dyhia's picture" />
              <div className="bottom-profile-description">
                <h1>{phrases["How you can contribute"]}</h1>
                <p>
                  {
                    phrases[
                      "The project relies partly on shared information. We are committed to providing information to all users, and you have the opportunity to contribute with information on fishing vessels. Please contact Dr. Belhabib for more information at"
                    ]
                  }
                  <a href="mailto:dyhia@ecotrust.ca">dyhia@ecotrust.ca</a>
                </p>
              </div>
            </div>
            <p>
              Dr. Dyhia Belhabib
              <br />
              <br />
              <a href="mailto:dyhia@ecotrust.ca">dyhia@ecotrust.ca</a>
              <br />
              <br />
              <a href="https://twitter.com/dyhiapadilla?lang=en">
                <i className="fab fa-twitter" /> @dyhiapadilla
              </a>
            </p>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
