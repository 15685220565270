import { h, Component } from "preact";
import API from "../../classes/API";
import Cookies from "js-cookie";

class AccountDetail extends Component {
  constructor() {
    super();
    this.state = {
      subscribedEEZ: [],
      editEEZ: false,
      userDetail: {},
      isVerified: false,
      filteredEEZ: [],
      originalEEZList: [],
      searchFilter: ""
    };
  }

  componentDidMount() {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    userDetail ? this.getUserDetail(userDetail) : (window.location.href = "/");
  }

  componentDidUpdate() {
    if (this.filterInput) {
      this.filterInput.focus();
    }
  }

  getUserDetail(userDetail) {
    Promise.all([
      API.request(`/api/users/${userDetail.id}/`, "GET"),
      API.request(`/api/eezs/map/`, "GET")
    ]).then(([userDetail, allEEZ]) => {
      const allEEZList = Object.entries(allEEZ)
        .slice(0, -1)
        .map(eez => {
          return { id: eez[0], eez_name: eez[1][2] };
        });

      const subscribedEEZ = userDetail.subscriptions.map(eez => {
        return { id: eez.id, eez_name: eez.name };
      });

      this.setState({
        userDetail: userDetail,
        isVerified: userDetail.is_approved,
        subscribedEEZ: subscribedEEZ,
        filteredEEZ: allEEZList.splice(0, 5),
        originalEEZList: allEEZList
      });
    });
  }

  generateSubscriptionPayload(subscribedEEZ) {
    const subscriptions = subscribedEEZ.map(eez => {
      return { id: eez.id };
    });

    return { subscriptions };
  }

  saveSubscriptionPreference() {
    const { userDetail, subscribedEEZ } = this.state;

    var csrftoken = Cookies.get("csrftoken");

    fetch(`/api/users/${userDetail.id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken
      },
      body: JSON.stringify(this.generateSubscriptionPayload(subscribedEEZ))
    })
      .then(response => response.json())
      .then(response => {
        this.setState({ editEEZ: false }, () => this.getUserDetail(userDetail));
      });
  }

  filterEEZ() {
    const { originalEEZList, searchFilter, subscribedEEZ } = this.state;

    const filteredEEZ = originalEEZList
      .filter(eez => !subscribedEEZ.find(subscribed => subscribed.id == eez.id))
      .filter(eez =>
        eez.eez_name.toLowerCase().startsWith(searchFilter.toLowerCase())
      )
      .splice(0, 3);

    this.setState({ filteredEEZ });
  }

  selectEEZ(eez) {
    this.setState(state => ({
      subscribedEEZ: [...state.subscribedEEZ, eez],
      searchFilter: ""
    }));
  }

  removeEEZList(eez) {
    const { subscribedEEZ } = this.state;

    const newSubscription = subscribedEEZ.filter(
      entry => entry.eez_name !== eez.eez_name
    );

    this.setState({ subscribedEEZ: newSubscription });
  }

  onEnterAddEEZ(event) {
    if (event.key === "Enter") {
      const { filteredEEZ, subscribedEEZ } = this.state;

      const newValue = filteredEEZ[0]
        ? [...subscribedEEZ, filteredEEZ[0]]
        : subscribedEEZ;
      this.setState({
        subscribedEEZ: newValue,
        searchFilter: ""
      });
    }
  }

  render() {
    const {
      subscribedEEZ,
      editEEZ,
      userDetail,
      isVerified,
      filteredEEZ,
      searchFilter
    } = this.state;

    const renderSubscribedEEZ = subscribedEEZ.length ? (
      subscribedEEZ.map(data => {
        return (
          <li className="eez-tag" key={data.id}>
            {!editEEZ ? (
              <a>{data.eez_name}</a>
            ) : (
              <button
                className="eez-edit-tag"
                onClick={() => this.removeEEZList(data)}
              >
                {data.eez_name}
              </button>
            )}
          </li>
        );
      })
    ) : !editEEZ ? (
      <p>You are not subscribed to any EEZ.</p>
    ) : (
      ""
    );

    const renderSuggestionEEZ = filteredEEZ.length ? (
      filteredEEZ.map(data => {
        return (
          <li key={data.id} onClick={() => this.selectEEZ(data)}>
            <a class="tag" key={data.id}>
              {data.eez_name}
            </a>
          </li>
        );
      })
    ) : (
      <span className="info-value">No Suggestions.</span>
    );

    const renderIfOfficial = userDetail.is_official ? (
      <div className="field-column-three">
        <div className="info-field">
          <span className="info-title">Organization Name</span>
          <span className="info-value">
            {userDetail.organization_name
              ? userDetail.organization_name
              : "N/A"}
          </span>
        </div>
        <div className="info-field">
          <span className="info-title">Organization Phone </span>
          <span className="info-value">
            {" "}
            {userDetail.organization_phone
              ? userDetail.organization_phone
              : "N/A"}{" "}
          </span>
        </div>

        <div className="info-field">
          <span className="info-title">Organization Address</span>
          <span className="info-value">
            {userDetail.organization_address
              ? userDetail.organization_address
              : "N/A"}
          </span>
        </div>
      </div>
    ) : (
      ""
    );

    return (
      <div className="basic-info">
        <div className="account-container">
          <div
            className={`alert-status ${
              isVerified || userDetail.is_superuser
                ? "is-verified"
                : "not-verified"
            }`}
          >
            {userDetail.is_superuser ? (
              <a href="/admin/login">
                You are an administrator. Click here to proceed to the Admin
                Dashboard
              </a>
            ) : isVerified ? (
              userDetail.is_official ? (
                "You are a verified official with access to private incident data. These data are strictly confidential."
              ) : (
                "You are a verified user. You don't have access to confidential data."
              )
            ) : (
              "Your account is not yet approved by the administrator. Your application is still being reviewed."
            )}
          </div>

          <div className="field-column-three">
            <div className="info-field">
              <span className="info-title">{phrases["Your Name"]}</span>
              <span className="info-value">
                {" "}
                {userDetail.first_name === "" && userDetail.last_name === ""
                  ? "Anonymous User"
                  : `${userDetail.first_name} ${userDetail.last_name}`}{" "}
              </span>
            </div>

            <div className="info-field">
              <span className="info-title">{phrases["Your Email"]}</span>
              <span className="info-value">
                {" "}
                {userDetail.email ? userDetail.email : "N/A"}{" "}
              </span>
            </div>

            <div className="info-field"></div>
          </div>

          {renderIfOfficial}

          {(isVerified || userDetail.is_superuser) && (
            <div className="info-field eez-field">
              <div className="text-field">
                <span className="info-title">
                  {phrases["List of EEZ Subscription"]}
                </span>
                <ul className="eez-subscription-description">
                  <li>You can only subscribed to a Maximum of 3 EEZs.</li>
                  <li>
                    When there are new incidents on your subscribed EEZs, you
                    will be notifed by Email.
                  </li>
                </ul>
                <ol className={editEEZ ? "eez-list-edit" : "eez-list"}>
                  {renderSubscribedEEZ}

                  {editEEZ && subscribedEEZ.length < 3 && (
                    <li class="tags-new">
                      <input
                        ref={input => {
                          this.filterInput = input;
                        }}
                        className="eez-input"
                        type="text"
                        placeholder="Type an EEZ..."
                        value={searchFilter}
                        onInput={e => {
                          e.preventDefault();
                          this.setState({ searchFilter: e.target.value }, () =>
                            this.filterEEZ()
                          );
                        }}
                        onKeyPress={event => this.onEnterAddEEZ(event)}
                      />
                    </li>
                  )}
                </ol>

                {editEEZ && searchFilter.length !== 0 && (
                  <div className="suggestion-container">
                    <p>Suggested EEZs:</p>
                    <ol className="eez-suggestion-list">
                      {renderSuggestionEEZ}
                    </ol>
                  </div>
                )}
                <button
                  className="btn-save"
                  onClick={() =>
                    editEEZ
                      ? this.saveSubscriptionPreference()
                      : this.setState({ editEEZ: true })
                  }
                >
                  {editEEZ ? "Save Subscription" : phrases["Edit Subscription"]}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AccountDetail;
