import { h, Component } from "preact";

import banner from "../../static/images/page-background-island.png";
import API from "../../classes/API";

import Footer from "../Footer/footer";
import BasicInfo from "./BasicInfo/BasicInfo";
import FlagHistory from "./FlagHistory/FlagHistory";
import OwnerHistory from "./OwnerHistory/OwnerHistory";
import IncidentsList from "./IncidentsList/IncidentsList";
import InfractionInfo from "./InfractionInfo/InfractionInfo";
import Stories from "./StoriesTab/Stories";
import PreviousNames from "./PreviousNames/PreviousNames";

class Vessel extends Component {
  constructor() {
    super();
    this.state = {
      vessel: [], // this holds the vessel details
      activeTab: "basicInfo"
    };
  }

  componentDidMount() {
    console.log(`loading details for vessel ${this.props.vesselId}`);

    // Load Vessel API data
    API.request(`/api/vessels/${this.props.vesselId}/`).then(response => {
      this.setState({
        vessel: response
      });
    });
  }

  // TABLES RENDERING FUNCTIONS ========================================

  onChangeActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  isActive(tab) {
    return this.state.activeTab === tab ? true : false;
  }

  onHandleVesselPanel() {
    // This function is responsible for handling the main display panel

    switch (this.state.activeTab) {
      case "basicInfo":
        return <BasicInfo vessel={this.state.vessel} />;

      case "flagHistory":
        return <FlagHistory vessel={this.state.vessel} />;

      case "ownerHistory":
        return <OwnerHistory vessel={this.state.vessel} />;

      case "incidents":
        return <IncidentsList vessel={this.state.vessel} />;
      case "story":
        return <Stories vessel={this.state.vessel} />;
      case "previousName":
        return <PreviousNames vessel={this.state.vessel} />;

      // case "infraction-info":
      //   return <InfractionInfo vessel={this.state.vessel} />;
    }
  }

  // end tabs =================================

  render() {
    return (
      <div className="vessel">
        <div className="banner">
          <div className="mobile-banner-text">
            {phrases["INCIDENT DETAILS"]}
          </div>

          <img src={banner} alt="costal image" />
          <a href="/" className="back-btn">
            <i className="fa fa-caret-left" aria-hidden="true" />{" "}
            {phrases["Back to Map"]}
          </a>
        </div>

        <div className="middle-sec">
          <h3>{phrases["INCIDENT DETAILS"]}</h3>
          <div className="incident-content">
            <div className="row vessels-info-row">
              <div className="col-lg-3 col-md-4 padding">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={
                      this.isActive("basicInfo")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected={this.isActive("basicInfo")}
                    onClick={() => this.onChangeActiveTab("basicInfo")}
                  >
                    {phrases["Basic Info"]}
                  </a>
                  {/* <a
                    className={
                      this.isActive('flagHistory')
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected={this.isActive('flagHistory')}
                    onClick={() => this.onChangeActiveTab('flagHistory')}
                  >
                    {phrases["Flag History"]}
                  </a> */}
                  <a
                    className={
                      this.isActive("ownerHistory")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected={this.isActive("ownerHistory")}
                    onClick={() => this.onChangeActiveTab("ownerHistory")}
                  >
                    {phrases["Owner History"]}
                  </a>
                  <a
                    className={
                      this.isActive("incidents")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected={this.isActive("incidents")}
                    onClick={() => this.onChangeActiveTab("incidents")}
                  >
                    {phrases["Incidents Information"]}
                  </a>
                  <a
                    className={
                      this.isActive("previousName")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected={this.isActive("previousName")}
                    onClick={() => this.onChangeActiveTab("previousName")}
                  >
                    {phrases["Previous Names"]}
                  </a>
                  <a
                    className={
                      this.isActive("story") ? "nav-link active" : "nav-link"
                    }
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected={this.isActive("story")}
                    onClick={() => this.onChangeActiveTab("story")}
                  >
                    {phrases["Fishermen Stories"]}
                  </a>
                  {/* <a
                    className={
                      this.isActive("infraction-info")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="# "
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected={this.isActive("infraction-info")}
                    onClick={() => this.onChangeActiveTab("infraction-info")}
                  >
                    Infraction Information
                  </a> */}
                </div>
              </div>

              <div className="ui text col-md-8 col-sm-12 mobile-table-container">
                {this.onHandleVesselPanel()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Vessel;
