export default {
  "Albanian Exclusive Economic Zone": [
    19.112965155739438,
    40.925046130515256
  ],
  "Algerian Exclusive Economic Zone": [
    3.541866791938096,
    37.20491529761551
  ],
  "American Samoa Exclusive Economic Zone": [
    -169.06378487218583,
    -13.855160710319423
  ],
  "Amsterdam Island & St. Paul Island Exclusive Economic Zone": [
    77.54153156583824,
    -38.294102119527714
  ],
  "Angolan Exclusive Economic Zone": [
    11.036768634666773,
    -11.700409035901389
  ],
  "Anguilla Exclusive Economic Zone": [
    -62.54416644211633,
    20.01516012172162
  ],
  "Antarctic 200NM zone beyond the coastline": [
    -12.02686365087983,
    -71.13472864418827
  ],
  "Antigua and Barbuda Exclusive Economic Zone": [
    -60.57712230424171,
    18.725433624133252
  ],
  "Arctic Sea": [
    -13.918663033391379,
    84.53581051376952
  ],
  "Argentinean Exclusive Economic Zone": [
    -62.15195344980954,
    -47.06071634696371
  ],
  "Aruban Exclusive Economic Zone": [
    -69.67498969494822,
    13.73371397438792
  ],
  "Ascension Exclusive Economic Zone": [
    -14.363318968089057,
    -7.947263952471354
  ],
  "Atlantic, Northwest": [
    -51.38597708271838,
    43.067925067663104
  ],
  "Atlantic, Northeast": [
    -4.568478533645025,
    67.25137647130522
  ],
  "Atlantic, Western Central \r": [
    -52.50609784524756,
    22.692190842620384
  ],
  "Atlantic, Eastern Central": [
    -24.713088929050606,
    13.670274375195687
  ],
  "Atlantic, Southwest \r": [
    -34.96488985952859,
    -31.03575245079577
  ],
  "Atlantic, Southeast": [
    0.22180948819293222,
    -31.131782902605742
  ],
  "Atlantic, Antarctic \r": [
    -9.633758898620268,
    -60.64051026984624
  ],
  "Australian Exclusive Economic Zone": [
    138.17515969677234,
    -27.508909910025626
  ],
  "Australian Exclusive Economic Zone (Macquarie Island)": [
    158.65213791564895,
    -54.876986540989385
  ],
  "Azerbaijanis Exclusive Economic Zone": [
    50.33089449446204,
    40.282802280712055
  ],
  "Bahamas Exclusive Economic Zone": [
    -75.59322458442296,
    25.09649737852879
  ],
  "Bahraini Exclusive Economic Zone": [
    50.70291035903917,
    26.327003614149117
  ],
  "Bangladeshi Exclusive Economic Zone": [
    90.50768974134444,
    20.73982640084512
  ],
  "Barbados Exclusive Economic Zone": [
    -57.99838863838219,
    13.322199832809952
  ],
  "Bassas da India Exclusive Economic Zone": [
    39.424911584399055,
    -20.861291266380704
  ],
  "Belgian Exclusive Economic Zone": [
    2.707641812738428,
    51.4651140455406
  ],
  "Belizean Exclusive Economic Zone": [
    -87.59829491732374,
    17.194542686574252
  ],
  "Beninese Exclusive Economic Zone": [
    2.421253622738894,
    4.846943063920766
  ],
  "Bermudian Exclusive Economic Zone": [
    -64.76775148932393,
    32.33406493025224
  ],
  "Bonaire Exclusive Economic Zone": [
    -68.36789564274476,
    12.699388442589616
  ],
  "Bosnian and Herzegovinian Exclusive Economic Zone": [
    17.63350242,
    42.89371415
  ],
  "Bouvet Exclusive Economic Zone": [
    3.35568222405904,
    -54.455641646154994
  ],
  "Brazilian Exclusive Economic Zone": [
    -39.633731208930534,
    -10.92683937956137
  ],
  "Brazilian Exclusive Economic Zone (Trindade)": [
    -29.09643397767864,
    -20.502377298511913
  ],
  "British Virgin Islands Exclusive Economic Zone": [
    -64.50463759411221,
    20.3836549095224
  ],
  "Bruneian Exclusive Economic Zone": [
    113.392200821883,
    6.056652540709613
  ],
  "Bulgarian Exclusive Economic Zone": [
    29.20378445685629,
    42.93366106901557
  ],
  "Cambodian Exclusive Economic Zone": [
    102.54491864657895,
    10.227112580635493
  ],
  "Cameroonian Exclusive Economic Zone": [
    9.285510028378345,
    3.5075679282006185
  ],
  "Canadian Exclusive Economic Zone": [
    -90.60159607134507,
    69.53063038454101
  ],
  "Cape Verdean Exclusive Economic Zone": [
    -24.16948998099366,
    16.09078218437593
  ],
  "Cayman Islands Exclusive Economic Zone": [
    -81.13910096856142,
    19.15276761992318
  ],
  "Chilean Exclusive Economic Zone": [
    -75.54240085864281,
    -41.28850208383882
  ],
  "Chilean Exclusive Economic Zone (Easter Island)": [
    -107.43880780246671,
    -26.823947221247785
  ],
  "Chilean Exclusive Economic Zone (San Felix and San Ambrosio islands)": [
    -79.99122790063504,
    -26.330688155603124
  ],
  "Chinese Exclusive Economic Zone": [
    117.86620760425177,
    27.444480132884525
  ],
  "Christmas Island Exclusive Economic Zone": [
    105.36551850225136,
    -11.241930640183293
  ],
  "Clipperton Exclusive Economic Zone": [
    -109.21737213001369,
    10.308530395470461
  ],
  "Cocos Islands Exclusive Economic Zone": [
    96.85450710935189,
    -12.031071265011494
  ],
  "Colombian Exclusive Economic Zone": [
    -78.06007279454572,
    8.604884384880771
  ],
  "Colombian Exclusive Economic Zone (Bajo Nuevo)": [
    -78.6342218961608,
    15.848561045898883
  ],
  "Colombian Exclusive Economic Zone (Quitasueño)": [
    -80.30431462111667,
    14.381257595023754
  ],
  "Colombian Exclusive Economic Zone (Serrana)": [
    -81.1613837212292,
    14.303452847303802
  ],
  "Colombian Exclusive Economic Zone (Serranilla)": [
    -79.85518413498883,
    15.79825640087323
  ],
  "Comoran Exclusive Economic Zone": [
    43.45716762281871,
    -11.428448015799454
  ],
  "Congolese Exclusive Economic Zone": [
    10.593604680802297,
    -5.338592319863749
  ],
  "Cook Islands Exclusive Economic Zone": [
    -161.3029573077214,
    -15.019347407495932
  ],
  "Costa Rican Exclusive Economic Zone": [
    -86.50679481729773,
    6.737057214528427
  ],
  "Croatian Exclusive Economic Zone": [
    15.648151678562932,
    43.46106457056769
  ],
  "Crozet Islands Exclusive Economic Zone": [
    51.21238781522159,
    -46.307058508511254
  ],
  "Cuban Exclusive Economic Zone": [
    -81.53391987745144,
    21.602859075575502
  ],
  "Curaçaoan Exclusive Economic Zone": [
    -68.97145945031873,
    13.14615120852229
  ],
  "Cypriote Exclusive Economic Zone": [
    32.63564772128051,
    34.48996029948249
  ],
  "Danish Exclusive Economic Zone": [
    9.371657773504307,
    56.1298401844626
  ],
  "Democratic Republic of the Congo Exclusive Economic Zone": [
    10.767129786985215,
    -6.393589555910448
  ],
  "Djiboutian Exclusive Economic Zone": [
    43.43381905673999,
    11.998717349224108
  ],
  "Dominican Exclusive Economic Zone": [
    -60.73735173229673,
    15.495686185142503
  ],
  "Dominican Republic Exclusive Economic Zone": [
    -69.67242797099053,
    19.137119134919853
  ],
  "Dutch Exclusive Economic Zone": [
    4.201639337437292,
    53.52786246543019
  ],
  "East Timorian Exclusive Economic Zone": [
    126.81229545015957,
    -9.771400633699018
  ],
  "Ecuadorian Exclusive Economic Zone": [
    -82.19197055038967,
    -0.9771777689913932
  ],
  "Ecuadorian Exclusive Economic Zone (Galapagos)": [
    -90.83204473995622,
    -0.046548237090414134
  ],
  "Egyptian Exclusive Economic Zone": [
    31.151127163645718,
    30.44942666279607
  ],
  "El Salvador Exclusive Economic Zone": [
    -89.74517438373607,
    11.847112010817392
  ],
  "Equatorial Guinean Exclusive Economic Zone": [
    5.458340462449033,
    -1.3403895314605894
  ],
  "Eritrean Exclusive Economic Zone": [
    40.59033607455674,
    15.814959469495859
  ],
  "Estonian Exclusive Economic Zone": [
    22.99456481725788,
    58.77443215603444
  ],
  "Faeroe Exclusive Economic Zone": [
    -6.9904245981395805,
    62.60263872556044
  ],
  "Fijian Exclusive Economic Zone": [
    95.95778912817728,
    -17.985873517556072
  ],
  "Finnish Exclusive Economic Zone": [
    21.883717597717197,
    61.7139929964704
  ],
  "French Exclusive Economic Zone": [
    -1.7132392493135618,
    45.95138964752014
  ],
  "French Guiana Exclusive Economic Zone": [
    -51.755647958538134,
    6.691100553438016
  ],
  "French Polynesian Exclusive Economic Zone": [
    -144.00474166853215,
    -18.73642676285377
  ],
  "Gabonese Exclusive Economic Zone": [
    8.739055100683995,
    -3.048159973274606
  ],
  "Gambian Exclusive Economic Zone": [
    -18.409220446584637,
    13.327943701698622
  ],
  "Georgian Exclusive Economic Zone": [
    40.55988625284904,
    42.445983214459574
  ],
  "German Exclusive Economic Zone": [
    8.493907140250899,
    54.554169949894415
  ],
  "Ghanaian Exclusive Economic Zone": [
    -0.8508433212110534,
    3.5015544830128147
  ],
  "Greek Exclusive Economic Zone": [
    23.971598534195756,
    36.54024007183959
  ],
  "Greenlandic Exclusive Economic Zone": [
    -32.05748618143955,
    75.45257521445835
  ],
  "Grenadian Exclusive Economic Zone": [
    -62.053231949744095,
    12.242625332433452
  ],
  "Guadeloupean Exclusive Economic Zone": [
    -59.881863690682934,
    16.78740034490834
  ],
  "Guam Exclusive Economic Zone": [
    144.00509225679843,
    12.930501270071991
  ],
  "Guatemalan Exclusive Economic Zone": [
    -92.09224690798153,
    12.551820591722555
  ],
  "Guernsey Exclusive Economic Zone": [
    -2.6563458507740205,
    49.638616254954215
  ],
  "Guinea Bissau Exclusive Economic Zone": [
    -17.560225462684304,
    10.583735647633599
  ],
  "Guinean Exclusive Economic Zone": [
    -15.612706299018429,
    9.190756243022737
  ],
  "Guyanese Exclusive Economic Zone": [
    -57.64152524732778,
    8.612394723553212
  ],
  "Haitian Exclusive Economic Zone": [
    -73.5461092157622,
    17.918901975181363
  ],
  "Heard and McDonald Islands Exclusive Economic Zone": [
    73.72910373550461,
    -53.497108080866624
  ],
  "Honduran Exclusive Economic Zone": [
    -83.85227021591494,
    16.928141332135013
  ],
  "Howland and Baker Islands Exclusive Economic Zone": [
    -176.72676908690454,
    0.7903704739176859
  ],
  "Icelandic Exclusive Economic Zone": [
    -18.44398296440795,
    64.69734217466085
  ],
  "Ile Europa Exclusive Economic Zone": [
    40.109125989924706,
    -23.535319056482123
  ],
  "Indian Exclusive Economic Zone": [
    76.11638073817473,
    14.620853945586704
  ],
  "Indian Exclusive Economic Zone (Andaman and Nicobar Islands)": [
    92.19937381872138,
    9.9911743670873
  ],
  "Indian Ocean, Western \r": [
    60.359612834806114,
    -21.071663977415756
  ],
  "Indian Ocean, Eastern \r": [
    103.18388357120936,
    -32.381938943605896
  ],
  "Indian Ocean, Antarctic \r": [
    79.68534784884655,
    -57.321835821893956
  ],
  "Indonesian Exclusive Economic Zone": [
    117.9571583200042,
    -3.3804520645255556
  ],
  "Iranian Exclusive Economic Zone": [
    53.893283124765816,
    29.755247629278056
  ],
  "Iraqi Exclusive Economic Zone": [
    48.58606130639206,
    29.777839429817053
  ],
  "Irish Exclusive Economic Zone": [
    -11.749968919723443,
    52.65281484270175
  ],
  "Israeli Exclusive Economic Zone": [
    34.08510447597197,
    32.57517924805191
  ],
  "Italian Exclusive Economic Zone": [
    12.81780088891415,
    39.58981045194997
  ],
  "Ivory Coast Exclusive Economic Zone": [
    -5.452276495674458,
    3.308850454935336
  ],
  "Jamaican Exclusive Economic Zone": [
    -77.29194852862565,
    16.73033514735962
  ],
  "Jan Mayen Exclusive Economic Zone": [
    -5.260314335221032,
    71.11294243397106
  ],
  "Japanese Exclusive Economic Zone": [
    138.31437733868256,
    30.151145883768685
  ],
  "Jarvis Island Exclusive Economic Zone": [
    -160.46725795041021,
    -0.9132958378584108
  ],
  "Jersey Exclusive Economic Zone": [
    -2.1927763971891503,
    49.12506674283908
  ],
  "Johnston Atoll Exclusive Economic Zone": [
    -169.50625640992396,
    16.75072699084816
  ],
  "Joint regime area Argentina / Uruguay": [
    -55.161096165831005,
    -36.25493245685351
  ],
  "Joint regime area Australia / East Timor": [
    127.14320374751703,
    -10.410429550800123
  ],
  "Joint regime area Colombia / Dominican Republic": [
    -72.50386110315951,
    15.018509676795293
  ],
  "Joint regime area Colombia / Jamaica": [
    -79.1465086260834,
    15.474000387515902
  ],
  "Joint regime area Costa Rica / Ecuador (Galapagos)": [
    -88.84133457052106,
    3.2263732474581137
  ],
  "Joint regime area Croatia / Slovenia": [
    13.336706881194093,
    45.51981392778895
  ],
  "Joint regime area Ecuador / Colombia": [
    -81.433581420622,
    1.4606149522233376
  ],
  "Joint regime area Guyana / Barbados": [
    -57.12641639110353,
    10.711132050503787
  ],
  "Joint regime area Honduras / Cayman Islands": [
    -83.94222222204688,
    18.701111110957047
  ],
  "Joint regime area Iceland / Denmark (Faeroe Islands)": [
    -10.972825341223748,
    63.15709399857732
  ],
  "Joint regime area Iceland / Norway (Jan Mayen)": [
    -8.499999999999972,
    69.2916666666334
  ],
  "Joint regime area Italy / France": [
    -0,
    0
  ],
  "Joint regime area Japan / Korea": [
    127.57808496817754,
    30.813808068096073
  ],
  "Joint regime area Nigeria / Sao Tome and Principe": [
    5.585932448507005,
    2.067332315580747
  ],
  "Joint regime area Peru / Ecuador": [
    -82.52758401849199,
    -3.3885052991881444
  ],
  "Joint regime area Senegal / Guinea Bissau": [
    -18.772909744904144,
    11.261540075532068
  ],
  "Joint regime area Spain / France": [
    -5.333333333066804,
    45.25416666655846
  ],
  "Joint regime area Sweden / Norway": [
    10.500953703850408,
    58.80998148136032
  ],
  "Joint regime area United Kingdom / Denmark (Faeroe Islands)": [
    -7.519099869876388,
    60.03566561627891
  ],
  "Joint regime area United States / Russia": [
    -17.22497449365838,
    61.161826351415236
  ],
  "Jordanian Exclusive Economic Zone": [
    34.940332641381055,
    29.427476660576378
  ],
  "Juan de Nova Exclusive Economic Zone": [
    42.09454390400808,
    -17.307141436374284
  ],
  "Kazakh Exclusive Economic Zone": [
    51.01883200190613,
    44.49260517289412
  ],
  "Kenyan Exclusive Economic Zone": [
    41.79025393679949,
    -3.533471199217624
  ],
  "Kerguelen Exclusive Economic Zone": [
    68.98243495360286,
    -48.88383649640608
  ],
  "Kiribati Exclusive Economic Zone (Gilbert Islands)": [
    172.80691534577448,
    -0.25851640682678917
  ],
  "Kiribati Exclusive Economic Zone (Line Islands)": [
    -154.72799916332096,
    -3.8207843926234037
  ],
  "Kiribati Exclusive Economic Zone (Phoenix Islands)": [
    -172.45430489095813,
    -3.7320097771902376
  ],
  "Kuwaiti Exclusive Economic Zone": [
    48.61620593763172,
    29.19921899638473
  ],
  "Latvian Exclusive Economic Zone": [
    21.34342465758659,
    57.104419562755105
  ],
  "Lebanese Exclusive Economic Zone": [
    34.90133773545362,
    33.90865256637422
  ],
  "Liberian Exclusive Economic Zone": [
    -10.177997778671992,
    3.8041472870237696
  ],
  "Libyan Exclusive Economic Zone": [
    18.50006131116947,
    33.14384570481807
  ],
  "Lithuanian Exclusive Economic Zone": [
    20.37135704960991,
    55.7501215579165
  ],
  "Madagascan Exclusive Economic Zone": [
    47.256402025192436,
    -20.908222903392733
  ],
  "Malaysian Exclusive Economic Zone": [
    109.13608904517034,
    5.159671739574101
  ],
  "Maldives Exclusive Economic Zone": [
    73.08796893420926,
    2.4557994117889206
  ],
  "Maltese Exclusive Economic Zone": [
    15.10687032425392,
    35.316488400252524
  ],
  "Marshall Islands Exclusive Economic Zone": [
    167.48813455276928,
    10.149893850994863
  ],
  "Martinican Exclusive Economic Zone": [
    -60.21935645022331,
    14.96792665987278
  ],
  "Mauritanian Exclusive Economic Zone": [
    -17.916990389093424,
    18.248870331599758
  ],
  "Mauritian Exclusive Economic Zone": [
    60.11147516510238,
    -16.814843596978076
  ],
  "Mexican Exclusive Economic Zone": [
    -105.3380105082447,
    21.360502653094628
  ],
  "Micronesian Exclusive Economic Zone": [
    150.32210383301856,
    6.770709604238432
  ],
  "Monégasque Exclusive Economic Zone": [
    7.587550166135915,
    43.34786269199956
  ],
  "Montenegrin Exclusive economic Zone": [
    18.67902679010866,
    41.859617087636806
  ],
  "Montserrat Exclusive Economic Zone": [
    -62.481147142021236,
    16.416130659418663
  ],
  "Moroccan Exclusive Economic Zone": [
    -9.847740183293515,
    32.78385740954871
  ],
  "Mozambican Exclusive Economic Zone": [
    37.960401445351,
    -20.364223476106833
  ],
  "Myanmar Exclusive Economic Zone": [
    94.69693850893397,
    15.035465313646501
  ],
  "Namibian Exclusive Economic Zone": [
    12.286963122142035,
    -23.649430508774195
  ],
  "Nauruan Exclusive Economic Zone": [
    166.12542939052418,
    -0.5907197375802188
  ],
  "New Caledonian Exclusive Economic Zone": [
    163.24534725113432,
    -21.021295895216667
  ],
  "New Zealand Exclusive Economic Zone": [
    78.14444229935522,
    -42.73333301789033
  ],
  "Nicaraguan Exclusive Economic Zone": [
    -83.40965073323278,
    12.868208219787771
  ],
  "Nigerian Exclusive Economic Zone": [
    4.8922594860094195,
    4.13576049638586
  ],
  "Niue Exclusive Economic Zone": [
    -169.33232138515035,
    -19.5213642261363
  ],
  "Norfolk Island Exclusive Economic Zone": [
    167.92954420615882,
    -29.093252056433666
  ],
  "North Korean Exclusive Economic Zone": [
    128.99509616877026,
    39.829696270312105
  ],
  "Northern Mariana Exclusive Economic Zone": [
    145.73858810438156,
    18.284770519958364
  ],
  "Norwegian Exclusive Economic Zone": [
    14.079572977897822,
    68.1851936419786
  ],
  "Oecussi Ambeno Exclusive Economic Zone": [
    124.26980427838514,
    -9.05082503668634
  ],
  "Omani Exclusive Economic Zone": [
    58.66951628115684,
    18.950308720169232
  ],
  "Overlapping claim Alhucemas Islands: Spain / Morocco": [
    -3.8895,
    35.2135
  ],
  "Overlapping claim Ceuta: Spain / Morocco": [
    -5.300943499624496,
    35.86036991377492
  ],
  "Overlapping claim Chafarinas Islands: Spain / Morocco": [
    -2.433333,
    35.183333
  ],
  "Overlapping claim Chagos Archipelago Exclusive Economic Zone: UK / Mauritius": [
    71.950125988101,
    -6.582739895153141
  ],
  "Overlapping claim Doumeira Islands: Djibouti / Eritrea": [
    43.22604291056553,
    12.765463983357819
  ],
  "Overlapping claim Falkland / Malvinas Islands Exclusive Economic Zone: UK / Argentina": [
    -58.34821796790261,
    -51.66848966345006
  ],
  "Overlapping claim Gibraltarian Exclusive Economic Zone": [
    -5.212287425137296,
    36.08977937119178
  ],
  "Overlapping claim Glorioso Islands: France / Madagascar": [
    47.09769796565908,
    -11.617877388383253
  ],
  "Overlapping claim Ile Tromelin: Reunion / Mauritus": [
    54.67324206836969,
    -15.711475467964124
  ],
  "Overlapping claim Kuril Islands: Japan / Russia": [
    147.94403916730258,
    43.90675145666386
  ],
  "Overlapping claim Liancourt Rocks: Japan / South Korea": [
    131.86416357333619,
    37.241755780382476
  ],
  "Overlapping claim Matthew and Hunter Islands: New Caledonia / Vanuatu": [
    172.03630787805864,
    -23.27836861511508
  ],
  "Overlapping claim Mayotte: France / Comoros": [
    45.305738681719625,
    -13.21218727326135
  ],
  "Overlapping claim Melilla: Spain / Morocco": [
    -2.9363129623732602,
    35.288898971910065
  ],
  "Overlapping claim Navassa Island: USA / Haiti": [
    -75.18291045129534,
    18.338555553723978
  ],
  "Overlapping claim Peñón de Vélez de la Gomera: Spain / Morocco": [
    -4.300803,
    35.172581
  ],
  "Overlapping claim Perejil Island: Spain / Morocco": [
    -5.418889,
    35.913889
  ],
  "Overlapping claim Qatar / Saudi Arabia / United Arab Emirates": [
    51.56752451124725,
    24.680670988675235
  ],
  "Overlapping claim Senkaku Islands: Japan / China / Taiwan": [
    124.12279262033388,
    26.52054180135243
  ],
  "Overlapping claim South China Sea": [
    114.1006765712217,
    13.139946949586724
  ],
  "Overlapping claim South Georgia and South Sandwich Exclusive Economic Zone: UK / Argentina": [
    -31.58232246108755,
    -56.44936099430274
  ],
  "Overlapping claim Ukrainian Exclusive Economic Zone": [
    33.63917611173522,
    44.95099518572116
  ],
  "Overlapping claim Western Saharan Exclusive Economic Zone": [
    -17.69405530084785,
    23.34324075280357
  ],
  "Overlapping claim: Canada / USA": [
    -139.85029980778444,
    72.03133640430703
  ],
  "Overlapping claim: Iran / United Arab Emirates": [
    55.168979764156845,
    26.010291937273916
  ],
  "Overlapping claim: Kenya / Somalia": [
    43.90502850254457,
    -2.2820146192056328
  ],
  "Overlapping claim: Puerto Rico / Dominican Republic": [
    -66.84213001575316,
    20.8923488974402
  ],
  "Overlapping claim: Sudan / Egypt": [
    36.8787221109579,
    22.61290081570799
  ],
  "Overlapping claim: Trinidad and Tobago / Venezuela / Guyana": [
    -57.88975610233701,
    10.564458736704324
  ],
  "Overlapping claim: Venezuela / Aruba / Dominican Republic": [
    -69.65292621598272,
    15.304026969813421
  ],
  "Overlapping claim: Venezuela / Colombia / Dominican Republic": [
    -71.0901506183074,
    15.053343253246554
  ],
  "Pacific, Northwest": [
    124.86842299042783,
    35.153652054734295
  ],
  "Pacific, Northeast": [
    -151.3490365019874,
    46.138324670316464
  ],
  "Pacific, Western Central": [
    97.70267438911799,
    10.083195937909172
  ],
  "Pacific, Eastern Central": [
    -140.23353722449463,
    13.18569339746968
  ],
  "Pacific, Southwest \r": [
    -90.87099791721667,
    -43.75073923992383
  ],
  "Pacific, Southeast": [
    -100.23649087764906,
    -29.306624820768434
  ],
  "Pacific, Antarctic": [
    -98.40322505284418,
    -65.34184426346022
  ],
  "Pakistani Exclusive Economic Zone": [
    64.73657448119282,
    23.54344610198218
  ],
  "Palau Exclusive Economic Zone": [
    133.06846390390328,
    6.440361211714257
  ],
  "Palestinian Exclusive Economic Zone": [
    34.23199026342746,
    31.571255038339917
  ],
  "Palmyra Atoll Exclusive Economic Zone": [
    -162.89816050029867,
    6.687439005295837
  ],
  "Panamanian Exclusive Economic Zone": [
    -80.34906424310282,
    8.288296180511681
  ],
  "Papua New Guinean Exclusive Economic Zone": [
    150.7268308147003,
    -5.469313352401683
  ],
  "Peruvian Exclusive Economic Zone": [
    -78.88364439433435,
    -11.89983436055194
  ],
  "Philippines Exclusive Economic Zone": [
    122.24571688666894,
    12.178495381569714
  ],
  "Pitcairn Islands Exclusive Economic Zone": [
    -127.42255013716266,
    -24.60628758676454
  ],
  "Polish Exclusive Economic Zone": [
    17.266004032085764,
    54.907440545906944
  ],
  "Portuguese Exclusive Economic Zone": [
    -10.923287885303948,
    38.31881667726798
  ],
  "Portuguese Exclusive Economic Zone (Azores)": [
    -27.974304498920674,
    38.47507547702558
  ],
  "Portuguese Exclusive Economic Zone (Madeira)": [
    -16.81045354248362,
    33.171016130624814
  ],
  "Protected Zone established under the Torres Strait Treaty": [
    142.41407568111958,
    -9.493851123263456
  ],
  "Puerto Rican Exclusive Economic Zone": [
    -66.78519543165407,
    17.798625453725336
  ],
  "Qatari Exclusive Economic Zone": [
    51.88439612365573,
    25.863584344777607
  ],
  "Réunion Exclusive Economic Zone": [
    54.71961784284205,
    -21.775304064171703
  ],
  "Romanian Exclusive economic Zone": [
    30.07932459468302,
    44.23985837619372
  ],
  "Russian Exclusive economic Zone": [
    93.8771810521205,
    71.31594248531061
  ],
  "Saba Exclusive Economic Zone": [
    -63.60125377003877,
    17.37764971143824
  ],
  "Saint-Barthélemy Exclusive Economic Zone": [
    -62.631063443394986,
    17.934701774734258
  ],
  "Saint-Martin Exclusive Economic Zone": [
    -63.20465054334514,
    18.03503937929089
  ],
  "Saint-Pierre and Miquelon Exclusive Economic Zone": [
    -56.38367436482288,
    45.87300641160792
  ],
  "Saint Kitts and Nevis Exclusive Economic Zone": [
    -62.89996483340684,
    16.99965341952288
  ],
  "Saint Lucia Exclusive Economic Zone": [
    -61.09854830773659,
    13.879482517292946
  ],
  "Saint Vincent and the Grenadines Exclusive Economic Zone": [
    -61.68853711250529,
    13.158473637689665
  ],
  "Samoan Exclusive Economic Zone": [
    -172.7410391736069,
    -13.306181858093106
  ],
  "Sao Tome and Principe Exclusive Economic Zone": [
    6.357229629305843,
    0.7761664530802375
  ],
  "Saudi Arabian Exclusive Economic Zone": [
    40.492095471339695,
    22.443957200572218
  ],
  "Senegalese Exclusive Economic Zone": [
    -18.499637775855714,
    13.698042142486518
  ],
  "Seychellois Exclusive Economic Zone": [
    52.469016598268574,
    -6.549058921401697
  ],
  "Sierra Leonian Exclusive Economic Zone": [
    -14.14020190545856,
    6.636956442776698
  ],
  "Singaporean Exclusive Economic Zone": [
    103.82988660259194,
    1.284504658863262
  ],
  "Sint-Eustatius Exclusive Economic Zone": [
    -63.129785792728725,
    17.338389040858576
  ],
  "Sint-Maarten Exclusive Economic Zone": [
    -63.12147696847644,
    17.93860496668517
  ],
  "Slovenian Exclusive Economic Zone": [
    13.562171364729487,
    45.561840822092314
  ],
  "Solomon Islands Exclusive Economic Zone": [
    163.61487676269184,
    -10.030687223137386
  ],
  "Somali Exclusive Economic Zone": [
    48.71786211402519,
    5.383107039967987
  ],
  "South African Exclusive Economic Zone": [
    24.44246004923733,
    -33.507323987222705
  ],
  "South African Exclusive Economic Zone (Prince Edward Islands)": [
    37.80873375908492,
    -46.83240259372141
  ],
  "South Korean Exclusive Economic Zone": [
    127.58604736250687,
    35.73141418350375
  ],
  "Spanish Exclusive Economic Zone": [
    -4.094346467869744,
    41.70392223895014
  ],
  "Spanish Exclusive Economic Zone (Canary Islands)": [
    -17.580072733835497,
    28.366951265830295
  ],
  "Sri Lankan Exclusive Economic Zone": [
    81.63111578302474,
    6.556111158038227
  ],
  "St. Helena Exclusive Economic Zone": [
    -5.708929233331258,
    -15.97234951771086
  ],
  "Sudanese Exclusive Economic Zone": [
    38.14451329632195,
    19.74400081968732
  ],
  "Surinamese Exclusive Economic Zone": [
    -54.771338146433585,
    7.592813950311529
  ],
  "Svalvard Exclusive Economic Zone": [
    18.309208453629875,
    78.7239033150919
  ],
  "Swedish Exclusive Economic Zone": [
    18.097431032198973,
    59.4379516725383
  ],
  "Syrian Exclusive Economic Zone": [
    35.4951031033293,
    35.325829360616545
  ],
  "Taiwanese Exclusive Economic Zone": [
    118.71885640403099,
    21.423721273438428
  ],
  "Tanzanian Exclusive Economic Zone": [
    41.147559080520075,
    -7.197754944447551
  ],
  "Thailand Exclusive Economic Zone": [
    99.62684063760939,
    9.162526061424506
  ],
  "Togolese Exclusive Economic Zone": [
    1.8214578536215216,
    4.638948240226438
  ],
  "Tokelau Exclusive Economic Zone": [
    -172.08441267416384,
    -8.632963340483219
  ],
  "Tongan Exclusive Economic Zone": [
    -174.76543586829456,
    -20.219626043744622
  ],
  "Trinidad and Tobago Exclusive Economic Zone": [
    -59.988253390201,
    10.958953768893668
  ],
  "Tristan Da Cunha Exclusive Economic Zone": [
    -11.21005641910015,
    -38.761467588607836
  ],
  "Tunisian Exclusive Economic Zone": [
    11.056226359437233,
    35.986183037489376
  ],
  "Turkish Exclusive Economic Zone": [
    33.20187506542353,
    40.523074242882636
  ],
  "Turkmen Exclusive Economic Zone": [
    52.640364592103694,
    39.291270044854166
  ],
  "Turks and Caicos Exclusive Economic Zone": [
    -70.86364520450306,
    22.798606119930586
  ],
  "Tuvaluan Exclusive Economic Zone": [
    74.32038796904183,
    -7.8127602733655115
  ],
  "United Arab Emirates Exclusive Economic Zone": [
    53.88702910546414,
    24.93120484614357
  ],
  "United Kingdom Exclusive Economic Zone": [
    -4.309174958826378,
    56.796163060716594
  ],
  "United States Exclusive Economic Zone": [
    -95.63966005864192,
    34.775911710229806
  ],
  "United States Exclusive Economic Zone (Alaska)": [
    -120.47301689413828,
    58.97896253889529
  ],
  "United States Exclusive Economic Zone (Hawaii)": [
    -151.1158013664131,
    24.015988209048647
  ],
  "Uruguayan Exclusive Economic Zone": [
    -53.324575284642634,
    -35.787863608032126
  ],
  "Vanuatu Exclusive Economic Zone": [
    168.55162119777611,
    -16.587732021427094
  ],
  "Venezuelan Exclusive Economic Zone": [
    -65.77783814556913,
    12.678029371899665
  ],
  "Vietnamese Exclusive Economic Zone": [
    108.71069696176374,
    11.617219802567549
  ],
  "Virgin Islander Exclusive Economic Zone": [
    -64.92276208113815,
    17.259920408221888
  ],
  "Wake Island Exclusive Economic Zone": [
    166.53079507345592,
    19.502387765204798
  ],
  "Wallis and Futuna Exclusive Economic Zone": [
    -172.77727032361145,
    -12.797086992164209
  ],
  "Yemeni Exclusive Economic Zone": [
    52.06434400575742,
    12.888567655894096
  ]
}