import { h, Component } from 'preact'
import Helper from '../../../classes/Helper'
import VesselTable from '../VesselTable'

class IncidentsList extends Component {
  componentDidMount() {
    console.log(this.props.vessel.incidents)
  }

  onTryRenderOrNA(data) {
    if (data) {
      if (data.includes('.0')) {
        data = data.replace('.0', '')
      }
    }

    return !data ? 'Not available' : data
  }

  onRenderIncidentsTables() {
    return this.props.vessel.incidents.map(incident => {
      const {
        entry_number,
        when,
        country_flag,
        arresting_authority,
        offense,
        offense_category,
        confidentiality_level,
        reference_link,
        eez,
        year
      } = incident

      return (
        <a>
          <a className="basic-info">
            <h1>{phrases["Incidents Information"]}</h1>
            <div className="ui divider" />
            <a className="ui list">
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">{phrases["Offense:"]}</div>
                  <div className="description">
                    {this.onTryRenderOrNA(offense)}
                  </div>
                </div>
              </a>
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">{phrases["Year of Incident(s):"]}</div>
                  <div className="description">
                    {this.onTryRenderOrNA(year)}
                  </div>
                </div>
              </a>
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">
                    {phrases["Flag at the time of the Incident:"]}
                  </div>
                  <div className="description">
                    {this.onTryRenderOrNA(country_flag.name)}
                  </div>
                </div>
              </a>
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">{phrases["EEZ or region of the incident:"]}</div>
                  <div className="description">
                    {this.onTryRenderOrNA(eez.name)}
                  </div>
                </div>
              </a>
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">{phrases["Arresting/Observing agent:"]}</div>
                  <div className="description">
                    {this.onTryRenderOrNA(arresting_authority)}
                  </div>
                </div>
              </a>
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">{phrases["Offense Category"]}</div>
                  <div className="description">
                    {this.onTryRenderOrNA(offense_category)}
                  </div>
                </div>
              </a>
              <a className="item">
                <i className="fas fa-caret-right" />
                <div className="content">
                  <div className="header">{phrases["Reference Link:"]}</div>
                  <div className="description">
                    {reference_link ? (
                      <a href={reference_link}>{phrases["Click here"]}</a>
                    ) : (
                      phrases['Not available']
                    )}
                  </div>
                </div>
              </a>
              {confidentiality_level === 'confidential' ||
              confidentiality_level === 'Confidential' ||
              confidentiality_level === 'Vessel name, MMSI, IMO' ? (
                <a className="item">
                  <i className="fas fa-caret-right" />
                  <div className="content">
                    <div className="header">
                      <a href="mailto:dyhia@ecotrust.ca">
                      {phrases["Name confidential - Contact Us"]}
                      </a>
                    </div>
                    <div className="description" />
                  </div>
                </a>
              ) : (
                <a className="item">
                  <i className="fas fa-caret-right" />
                  <div className="content">
                    <div className="header">{phrases["Name:"]}</div>
                    <div className="description">
                      {Helper.onBeautifyTitle(
                        this.props.vessel.name.toLowerCase()
                      )}
                    </div>
                  </div>
                </a>
              )}
            </a>
          </a>
        </a>
      )
    })
  }

  render() {
    if (!this.props.vessel.incidents.length) {
      return (
        <div className="basic-info">
          <div className="content">
            <h1>{phrases["Sorry!"]}</h1>
            <p>{phrases["We have no data about the vessel incidents."]}</p>
          </div>
        </div>
      )
    }

    return (
      <div className="basic-info">{this.onRenderIncidentsTables()}</div>
      // <div className="basic-info">
      //   <div className="ui divider lists">{this.onRenderIncidentsList()}</div>
      // </div>
    )
  }
}

export default IncidentsList
