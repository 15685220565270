import { h, Component } from "preact";
import Router from "preact-router";
import Map from "./map-page";
import Vessel from "./Vessel/vessel.js";
import "./app.scss";
import About from "./About/about";
import Contact from "./Contact/Contact";
import Header from "./Header/Header";
import VesselSearch from "./VesselSearch/VesselSearch";
import IUUModal from "./UI/IUUModal";
import Profile from "./Vessel/Profile/Profile";
import RequestInfo from "./RequestInfo/RequestInfo";
import API from "../classes/API";
import Stories from "./Stories/Stories";
import StoryDetail from "./Stories/StoryDetail";
import Account from "./Account/Account";
import { createBrowserHistory } from "history";

class App extends Component {
  constructor() {
    super();
    this.state = {
      phrases: {},
      loaded: false
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.handleLeavePage = this.handleLeavePage.bind(this);
    this.loadPhrases = this.loadPhrases.bind(this);
  }
  handleLeavePage(e) {
    /*
     * TO DO: CLEAR CurrentLanguage data when tab is closed not on refresh
     */
    // localStorage.removeItem("currentLanguage");
  }
  componentDidMount() {
    this.loadPhrases();
    window.addEventListener("beforeunload", this.handleLeavePage);
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleLeavePage);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  loadPhrases() {
    let getLang = localStorage.getItem("currentLanguage");
    let language = getLang != null ? getLang : "en";
    API.request(`/api/phrases/${language}`, "GET").then(response => {
      this.setState({
        loaded: true
      });
    });
  }

  changeLanguage(lang) {
    //Set localStorage item
    localStorage.setItem("currentLanguage", lang);
    window.location.reload(true);
  }

  render() {
    const history = createBrowserHistory();

    history.listen(history => {
      window.scrollTo(0, 0);
    });

    phrases = window.phrases;
    return (
      <div>
        <Header getLang={this.changeLanguage} />
        <IUUModal />
        <Router history={history}>
          <RequestInfo path="/info/request-or-submit" />
          <Map path="/" />
          <Vessel path="/vessel/:vesselId" />
          <Profile path="/company/:profileId" />
          <VesselSearch path="/search/:keyword" />
          <About path="/about" />
          <Contact path="/contact" />
          <Stories path="/fishermen-story/" />
          <StoryDetail path="/fishermen-story/:storyId" />
          <Account path="/account-detail" />
          <Map path="/:params" />
        </Router>
      </div>
    );
  }
}

export default App;
